import { useEffect } from "react";
import React from "react";
import '../../envios.scss';
import { useSelector } from "react-redux";

export default function EnvioDetail({
    envio, 
}) {
    const serviciosEnvio = useSelector(state => {
        const { servicios_envio } = state;
        return servicios_envio;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    useEffect(() => {
    }, [envio]);

    const getServicio = (servicioId) => { return serviciosEnvio.find(s => s.id === servicioId) }
    const getTipoPieza = (tipoPiezaId) => { return tipoPiezas.find(tp => tp.id  === tipoPiezaId) }

    return (
        <div className="w-100 d-flex flex-column justify-content-center mt-2 envio-info">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
            <div className="col-12 col-md-6 d-flex flex-column ps-0 pe-md-3">
                    <h5 className="fw-bold">Origen del paquete:</h5>
                    <p>{envio.remitente.nombre}</p>
                    <p>{envio.remitente.telefono}</p>
                    <p>{envio.remitente.email}</p>
                    <p>{envio.remitente.direccion}</p>
                    <p>{envio.remitente.codigo_postal} {envio.remitente.poblacion}</p>
                    <p>{envio.remitente.area}, {envio.remitente.pais.nombre}</p>
                </div>
                
                <div 
                    className="col-12 col-md-6 d-flex flex-column ps-0 ps-md-3"
                    style={{borderLeft: '1px solid #000'}}
                >
                    <h5 className="fw-bold">Destino del paquete:</h5>
                    <p>{envio.destinatario.nombre}</p>
                    <p>{envio.destinatario.telefono}</p>
                    <p>{envio.destinatario.email}</p>
                    <p>{envio.destinatario.direccion}</p>
                    <p>{envio.destinatario.codigo_postal} {envio.destinatario.poblacion}</p>
                    <p>{envio.destinatario.area}, {envio.destinatario.pais.nombre}</p>
                </div>
            </div>

            <hr />

            <div className="w-100 d-flex flex-column">
                <h5 className="fw-bold">Datos del paquete:</h5>

                <div className="w-100">
                    { envio.piezas.length > 0 ? 
                        envio.piezas.map(pieza => {
                            return (
                                <p className="mt-2" key={'envio-pieza-'+pieza.id}>{pieza.nombre} | #{pieza.id_referencia_plataforma}</p>
                            )
                        })
                     : envio.tipo_pieza_id ? <p>Tipo de pieza: <span className="fw-bold">{getTipoPieza(envio.tipo_pieza_id)?.nombre}</span></p> : '-'
                    }
                </div>

                <div className="w-100 table-response">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-center">Peso</th>
                                <th className="text-center">Largo</th>
                                <th className="text-center">Ancho</th>
                                <th className="text-center">Alto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">
                                    {envio.piezas.length > 0 && envio.piezas.every(p => p.peso !== null && p.peso !== undefined)
                                        ? envio.piezas.map(pieza => pieza.peso).join(", ")
                                        : envio.peso} KG
                                </td>
                                <td className="text-center">
                                    {envio.piezas.length > 0 && envio.piezas.every(p => p.medidas)
                                        ? envio.piezas.map(pieza => pieza.medidas.split("x")[0]).join(", ")
                                        : envio.largo} CM
                                </td>
                                <td className="text-center">
                                    {envio.piezas.length > 0 && envio.piezas.every(p => p.medidas)
                                        ? envio.piezas.map(pieza => pieza.medidas.split("x")[1]).join(", ")
                                        : envio.ancho} CM
                                </td>
                                <td className="text-center">
                                    {envio.piezas.length > 0 && envio.piezas.every(p => p.medidas)
                                        ? envio.piezas.map(pieza => pieza.medidas.split("x")[2]).join(", ")
                                        : envio.alto} CM
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-100 d-flex flex-column mt-4">
                <h5 className="fw-bold">Información del transportista:</h5>
                
                <div className="w-100 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>servicio</th>
                                <th className="text-center">seguimiento</th>
                                <th className="text-center">codigo envio</th>
                                <th className="text-center">seguro</th>
                                <th className="text-center">valor asegurado</th>
                                <th className="text-center">precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getServicio(envio.servicio_envio_id)?.nombre}</td>
                                <td className="text-center">
                                    <span className="d-block fw-bold">{envio.numero_seguimiento}</span>
                                    <small className="text-info">{envio.url_seguimiento}</small>
                                </td>
                                <td className="text-center">{envio.codigo_envio}</td>
                                <td className="text-center">
                                    <span className={"fw-bold " +( envio.seguro ? 'text-green' : 'text-danger')}>{envio.seguro ? 'SI' : 'NO'}</span>
                                </td>
                                <td className="text-center">{envio.valor_asegurado}€</td>
                                <td className="text-center">{envio.precio}€</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="w-100 mt-3">
                    <label>Observaciones:</label>
                    <p>{envio.observaciones ? envio.observaciones : '-'}</p>
                </div>
            </div>
        </div>
    );
}