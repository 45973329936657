import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState, useEffect } from "react";
import './file_grid.scss';
import LightBoxImages from "../LightBoxImages";
import placeholderVideo from '../../../../../../images/placeholder_video.png';

export default function FilesGrid({
    filesList,
    incidenciaId,
    canDelete,
    onDelete,
    onDownload
}) {
    const [files, setFiles] = useState(filesList);
    const [validFiles, setValidFiles] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        if (filesList) {
            const imgSRCs = filesList?.filter(f => f.ext === 'jpg' || f.ext === 'jpeg' || f.ext === 'png' || f.ext === 'mov' || f.ext === 'mp4')
                .map((img) => {
                    if (img.ext === 'jpg' || img.ext === 'jpeg' || img.ext === 'png') {
                        return `data:image/jpeg;base64,${img.file}`;
                    } else {
                        if (img.ext === 'mov') {
                            return `data:video/mov;base64,${img.file}`;
                        } else {
                            return `data:video/mp4;base64,${img.file}`;
                        }
                    }
                })

            setImagesSRCs(imgSRCs);
            setFiles(filesList);
            setValidFiles(filesList?.filter(file => file.name && file.ext && file.file !== null));
        }
    }, [filesList]);

    const handleDeleteFile = (file) => onDelete(file);
    const handleDownload = (file) => onDownload(file);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    return (
        <div className="w-100">
            {validFiles.length > 0 ?
                <div className="w-100 file-grid mt-4">
                    {files
                        .filter(file => file.name && file.ext && file.file !== null)
                        .map((file, i) => {
                            return (
                                <div className="d-flex flex-column item mb-4" key={'file' + i}>
                                    <span style={{ cursor: 'pointer' }} className="align-self-stretch h-100">
                                        {file.ext === 'pdf' ?
                                            <object
                                                data={`data:application/pdf;base64,${file.file}`}
                                                type="application/pdf"
                                                width="100%"
                                                height="400px"
                                            />
                                            :
                                            <img
                                                src={(file.ext === 'mp4' || file.ext === 'mov') ? placeholderVideo : `data:image/jpeg;base64,${file.file}`}
                                                alt={'Imagen incidéncia ' + incidenciaId}
                                                onClick={() => handleOpenLightBox(i)}
                                            />}
                                    </span>

                                    <div className="w-100 d-flex justify-content-center mb-3">
                                        <label className="fw-bold me-2">{file.name}</label>

                                        <span title="Descargar" style={{ cursor: 'pointer' }} onClick={() => handleDownload(file)}>
                                            <FontAwesomeIcon icon={faDownload} size="1x" className="action" color="#215732" />
                                        </span>
                                        {canDelete &&
                                            <span title="Eliminar" className="ms-3" style={{ cursor: 'pointer' }} onClick={() => handleDeleteFile(file)}>
                                                <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                            </span>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
                :
                <p>No hay archivos adjuntos</p>
            }

            {(openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    );
}