import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faExclamationCircle, faPen, faUser } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { CustomTooltip, isCanariasCeutaOrMelillaOrBaleares } from "../../helpers/functionalities";

export default function EntityCard({
    cliente, 
    direccionEnvioId, 
    canEdit,
    canChange,
    onEdit, 
    onChange, 
}) {
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const tipoClientesB2b = useSelector(state => {
        const { tipo_clientes_b2b } = state;
        return tipo_clientes_b2b;
    });
    const tarifas = useSelector(state => {
        const { tarifas } = state;
        return tarifas;
    });
    const [currentCliente, setCurrentCliente] = useState(cliente);
    const [hasWarning, setHasWarning] = useState(false);

    useEffect(() => {
      setCurrentCliente(cliente);
      const {codigo_postal_facturacion, poblacion_facturacion, pais_facturacion_id} = cliente;

      if(pais_facturacion_id) {
          if(pais_facturacion_id === 1 && (codigo_postal_facturacion && poblacion_facturacion)) {
              if(isCanariasCeutaOrMelillaOrBaleares(parseInt(cliente.codigo_postal_facturacion))) setHasWarning(true); 
          } else { setHasWarning(true); }
      }
      
    }, [cliente, direccionEnvioId]);

    const getTipoClienteName = (tipoId) => tipoClientes.find(f => f.id === Number(tipoId))?.nombre;
    const getTipoClienteB2bName = (tipoId) => tipoClientesB2b.find(f => f.id ===  Number(tipoId))?.nombre;

    const edit = () => onEdit(true);
    const change = () => onChange(true);
    
    return (
        <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-between custom-card">
           <div className="d-flex align-items-start">
                <div className="d-flex flex-column me-3">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUser} size="1x" color="#215732" />
                    </span>
                </div>
               

                <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-1">
                        <h3 className="text-success"> {currentCliente?.nombre}</h3>

                        <div className="d-flex align-items-center ms-2">
                            { (hasWarning) ?
                                <CustomTooltip
                                    title={
                                        <React.Fragment>
                                            <small className="m-0">Atención! Cliente de Baleares, Canarias, Ceuta, Melilla o fuera de España.</small>
                                        </React.Fragment>
                                    }
                                >
                                    <span className="align-self-start me-2" style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#ffc107" /> 
                                    </span>
                                </CustomTooltip>
                                : ''
                            }
                        </div>
                    </div>
                    <p>{currentCliente?.cif ? currentCliente.cif : '-'}</p>
                    <p className="text-uppercase">
                        { getTipoClienteName(currentCliente.tipo_cliente_id) }
                        { (currentCliente.b2b) && 
                            <span className="text-uppercase">
                                <span className="mx-2">|</span>
                                 { getTipoClienteB2bName(currentCliente.tipo_cliente_b2b_id) }
                            </span>
                        }
                    </p>
                    <p className="text-uppercase">{tarifas.find(f => f.id === currentCliente.tarifa_cliente_id)?.nombre}</p>
                    <p className="text-uppercase">
                        Bolsa disponible: 
                        <span className="fw-bold text-green ms-2">{currentCliente.bolsa_disponible}€</span>
                    </p>
                </div>
           </div>

           <div className="d-flex flex-column mt-2 mt-md-0">
                <label className="mb-1">Información de contacto</label>
                <p>Teléfono: {currentCliente?.telefono}</p>
                <p>Email: {currentCliente?.email ? currentCliente.email : '-'}</p>
           </div>

           <div className="d-flex flex-column mt-2 mt-md-0">
                <label className="mb-1">Dirección de facturación</label>
                <p>{currentCliente?.direccion_facturacion ? currentCliente?.direccion_facturacion : '-'}</p>
                <p>
                    {(currentCliente.codigo_postal_facturacion + ' ' + currentCliente.poblacion_facturacion)},
                </p>
                <p>
                    {currentCliente?.area_facturacion}, {currentCliente?.pais_facturacion_id ? currentCliente.pais_facturacion.nombre : '-'} 
                </p>
            </div>

            { (direccionEnvioId) ? 
                <div className="d-flex flex-column mt-2 mt-md-0">
                    <label className="mb-1">Dirección de envío:</label>
                    { currentCliente?.direcciones.map((direccion, i) => {
                        return direccion.id === direccionEnvioId &&  (
                            <div className="d-flex flex-column" key={'direccion-' + direccion.id}>
                                <p>{direccion.nombre} &nbsp;&nbsp; {direccion.telefono}</p>
                                <p>{direccion?.direccion ? direccion.direccion : '-'}</p>
                                <p>
                                    {(direccion.codigo_postal + ' ' + direccion.poblacion)},
                                </p>
                                <p>
                                    {direccion?.area}, {direccion?.pais_id ? direccion.pais.nombre : '-'} 
                                </p>
                            </div>
                        )                       
                    })}
                </div>
                : ''
            }

           <div className="d-flex flex-column align-items-center justify-content-between">
            { canEdit && 
                <button type="button" className="btn btn-action" onClick={() => edit()}>
                    <FontAwesomeIcon icon={faPen} size="1x" color="#215732" />
                </button>
            }

            { canChange && 
                <button type="button" className="btn btn-action" onClick={() => change()}>
                    <FontAwesomeIcon icon={faExchangeAlt} size="1x" color="#215732" />
                </button>
            }
           </div>
        </div>
    )
}