import AvisosModal from "./components/AvisosModal";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import DocumentosModal from "./components/DocumentosModal";
import IncidenciaSteps from "./components/IncidenciaSteps";
import InfoCard from "./components/InfoCard";
import InfoModal from "../../../shared/components/InfoModal";
import Loading from "../../../shared/components/Loading";
import PerdidaForm from "./components/PerdidaForm";
import React from "react";
import ValeDescuentoModal from "./components/ValeDescuentoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDocumentationIncidencia, deleteDocumentationIncidencia, fetchIncidencia } from "../../../../../../redux/features/fichaIncidencia";
import { downloadFile, getIncidenciaFiles } from "../../../shared/services/incidencias";
import { faLongArrowAltLeft, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';

export default function FichaIncidencia() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { incidenciaId } = useParams();
    const [isDocuments, setIsDocuments] = useState(false);
    const [isOpenAvisos, setIsOpenAvisos] = useState(false);
    const [isOpenValeDescuento, setIsOpenValeDescuento] = useState(false);
    const [openConfirmExit, setOpenConfirmExit] = useState(false);

    const currentIncidencia = useSelector(state => {
      return state.currentIncidencia.entity?.incidencia
    })
    const status = useSelector(state => {
        return state.currentIncidencia.status
    })

    useEffect(() => {
      dispatch(fetchIncidencia(incidenciaId))

      return () => {
        dispatch({type: "incidencia/clear", payload: null })
      };
    }, []);

    const handleDeleteFile = async (file) => { 
      dispatch({type: 'loading/set', payload: true});
      dispatch(deleteDocumentationIncidencia(currentIncidencia.id, {documento: file.name}))
      setIsDocuments(false);
    }

    const handleDownload = async (file) => {
      dispatch({type: 'loading/set', payload: true});
      const fileResult = await downloadFile(currentIncidencia.id, {documento: file.name});
      if(fileResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('Documento descargado correctamente!')
        const url = window.URL.createObjectURL(new Blob([fileResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
    }

    const handleOpenDocuments = async () => {
      if(typeof currentIncidencia.documentacion === 'string') {
        dispatch({type: 'loading/set', payload: true});
        const incidenciaFiles = await getIncidenciaFiles(currentIncidencia.id, currentIncidencia.documentacion);
        if(incidenciaFiles) {
          dispatch({type: 'loading/set', payload: false});
          currentIncidencia.documentacion = incidenciaFiles.data;
        }
      }
      setIsDocuments(true);
    }
    
    const handleCloseUpload = () => {setIsDocuments(false)};
    const handleUploadFiles = async (data) => {
      const files = data.files;
      let incidenciaFiles = new FormData();
      incidenciaFiles.append("id", currentIncidencia.id);
  
      if(files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          incidenciaFiles.append(`files[${i}]`, files[i])
        }
        
        dispatch({type: 'loading/set', payload: true});
        dispatch(addDocumentationIncidencia(currentIncidencia.id, incidenciaFiles))
        setIsDocuments(false);
      }
    }

    const handleOpenAvisos = () => setIsOpenAvisos(true);
    const handleCloseAvisos = () => setIsOpenAvisos(false);

    const handleOpenValeDescuento = () => setIsOpenValeDescuento(true);
    const handleCloseValeDescuento = () => setIsOpenValeDescuento(false);

    const handleExit = () => { setOpenConfirmExit(true); }
    const handleConfirmExit = (confirm) => {
      setOpenConfirmExit(false);

      if(confirm) navigate(-1);
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
      return ( <Loading /> );
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
      return (
        <div className="w-100 d-flex flex-column incidencia content">
          <div className="w-100 mb-4">
              <button type="button" className="btn btn-add" onClick={() => (handleExit())}>
                  <span className="add">
                      <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                  </span>
                  Atrás
              </button>
          </div>

          {currentIncidencia &&
            <div className="w-100 d-flex flex-column">
              <InfoCard 
                incidencia={currentIncidencia}
                cliente={currentIncidencia?.cliente} 
                direccionEnvioId={currentIncidencia?.direccion_envio_id}
                proveedor={currentIncidencia.proveedor}
                piezas={currentIncidencia.piezas}
                showPrecioCompra={false}
                contacto={currentIncidencia?.proveedor_contacto}
                valeDescuento={currentIncidencia?.vale_descuento}
                onViewAvisos={handleOpenAvisos}
                onViewValeDescuento={handleOpenValeDescuento}
              />

              <div className="w-100 d-flex align-items-end justify-content-between my-4">
                <PerdidaForm
                  incidencia={currentIncidencia}
                />

                <button className="btn btn-primary ms-3" onClick={() => handleOpenDocuments()}>Documentos</button>
              </div>

              { currentIncidencia?.comentarios_cliente && 
                (currentIncidencia?.comentarios_cliente !== "" && currentIncidencia?.comentarios_cliente !== null) ?
                <div className="w-100 alert alert-warning">
                  <div className="w-100 d-flex align-items-center">
                    <FontAwesomeIcon icon={faUserAlt} size="1x" color="#ffc107" />

                    <div className="d-flex flex-column ms-3">
                      <label>Comentario del cliente:</label>
                  
                      <p>
                        {currentIncidencia?.comentarios_cliente}
                      </p>
                    </div>
                  </div>
                </div>
                : ''
              }
                
              <div className="w-100">
                <IncidenciaSteps 
                  incidencia={currentIncidencia}
                  incidenciaId={currentIncidencia?.id}
                  tipoId={currentIncidencia?.tipo_incidencia_id}
                  logs={currentIncidencia?.steps} />
              </div>
            </div>
          }

          { isDocuments && 
            <InfoModal
              state={isDocuments}
              title="Documentos"
              width={'lg'}
              content={
                <DocumentosModal
                  currentIncidencia={currentIncidencia}
                  incidenciaId={incidenciaId}
                  onDeleteFile={handleDeleteFile}
                  onDownload={handleDownload}
                  onUploadFiles={handleUploadFiles}
                />
              }
              onClose={handleCloseUpload}
            ></InfoModal>
          }

          { isOpenAvisos && 
            <AvisosModal
              state={isOpenAvisos}
              title={"Avisos incidéncia " + currentIncidencia.numero}
              onClose={handleCloseAvisos}
              incidencia={currentIncidencia}
            ></AvisosModal>
          }

          { isOpenValeDescuento &&
            <ValeDescuentoModal
              title={"Vale de descuento de la incidéncia " + currentIncidencia.numero} 
              state={isOpenValeDescuento}
              incidencia={currentIncidencia}
              onClose={handleCloseValeDescuento}
            />
          }

          { (openConfirmExit) && 
            <ConfirmModal 
                onConfirmAction={handleConfirmExit} 
                title={'Salir'} 
                description={'Estás seguro de salir?'}
                content={null}
                onClose={() => handleConfirmExit(false)}
                state={openConfirmExit}>
            </ConfirmModal>
          }
        </div>
      )
    }
}