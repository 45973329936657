import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getIncidencias = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/incidencias' + pagination + searcher).then(res => res.data);
}

export const getIncidenciasActivas = async () => {
    return await API.get('/incidencias_activas').then(res => res.data);
}

export const getIncidencia = async (incidenciaId) => {
    return API.get('/incidencias/' + incidenciaId).then(res => res.data);
}

export const newIncidencia = async (incidencia) => {
    return API.post('/incidencias', incidencia).then(res => res.data);
}

export const cambiarSubestado = async (incidencia) => {
    return API.post('/incidencias/' + incidencia.id + '/subestado', incidencia).then(res => res.data);
}

export const cambiarResponsable = async (incidencia) => {
    return API.post('/incidencias/' + incidencia.id + '/responsable', incidencia).then(res => res.data);
}

export const marcarComoFinalizada = async (incidenciaId) => {
    return API.post('/incidencias/' + incidenciaId + '/finalizada', {}).then(res => res.data);
}

export const marcarComoActiva = async (incidenciaId) => {
    return API.post('/incidencias/' + incidenciaId + '/activa', {}).then(res => res.data);
}

export const marcarComoFinanzas = async (incidenciaId) => {
    return API.post('/incidencias/' + incidenciaId + '/finanzas', {}).then(res => res.data);
}

export const uploadDocuments = async (incidenciaId, documentos) => {
    return API.post('/incidencias/' + incidenciaId + '/upload_documentation', documentos, headers).then(res => res.data);
}

export const deleteIncidenciaFile = async (incidenciaId, documento) => {
    return API.post('/incidencias/' + incidenciaId + '/delete_documentation', documento).then(res => res.data);
}

export const getIncidenciaFiles = async (incidenciaId, documentacion) => {
    return API.post('/incidencias/' + incidenciaId + '/files', { documentacion }).then(res => res.data);
}

export const downloadFile = async (incidenciaId, documento) => {
    return API.post('/incidencias/' + incidenciaId + '/download', documento, {responseType: 'blob'}).then(res => res.data);
}

export const exportPerdidas = async (filters) => {
    return API.post('/incidencias/export', filters, {responseType: 'blob'}).then(res => res.data);
}

export const exportCulpaRecomotor = async (filters) => {
    return API.post('/incidencias/export-culpa-recomotor', filters, {responseType: 'blob'}).then(res => res.data);
}

export const updateIncidencia = async (incidenciaId, incidencia) => {
    return API.put('/incidencias/' + incidenciaId, incidencia).then(res => res.data);
}

export const deleteIncidencia = async (incidenciaId) => {
    return API.delete('/incidencias/' + incidenciaId).then(res => res.data);
}

/********** VALES DE DESCUENTO **********/

export const newValeDescuento = async (valeDescuento) => {
    return API.post('/vales-descuentos', valeDescuento).then(res => res.data);
}

export const enviarValeDescuento = async (valeDescuentoId) => {
    return API.post('/vales-descuentos/' + valeDescuentoId + '/enviar', {}).then(res => res.data);
}

export const updateValeDescuento = async (valeDescuento) => {
    return API.put('/vales-descuentos/' + valeDescuento.id, valeDescuento).then(res => res.data);
}

export const deleteValeDescuento = async (valeDescuentoId) => {
    return API.delete('/vales-descuentos/' + valeDescuentoId).then(res => res.data);
}