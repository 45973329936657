import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Searcher({ onSearch }) {
  const { register, handleSubmit: handleSubmit, setValue, watch } = useForm();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    onSearch(data);
  };

  const clear = () => {
    setValue("buscador", "");
    onSearch({ buscador: "" });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex" style={{ gap: ".4rem" }}>
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="form-control"
          placeholder="ID Referencia Plataforma"
          {...register("buscador")}
          style={{ height: "40.5px", width: "200px" }}
        />
        {watch("buscador") && (
          <FontAwesomeIcon
            icon={faTimes}
            size="1x"
            className="action"
            color="#215732"
            style={{ marginLeft: "-1.5rem", paddingRight: ".88rem" }}
            onClick={clear}
          />
        )}
      </div>
      <button type="submit" className="btn btn-primary">
        Buscar
      </button>
    </form>
  );
}
