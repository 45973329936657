import { useState, useEffect } from "react";
import React from "react";
import LightBoxImages from "../../../shared/components/LightBoxImages";
import { getImgsDevolucionMercancias } from '../../../shared/services/devoluciones_mercancias';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

export default function Detail({
    devolucion
}) {
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [imagenes, setImagenes] = useState(null);
 
    useEffect(() => {
        if(!imagenes) {
            getImages(devolucion.id);
        } else {
            const imgSRCs = imagenes?.map((imagen) => {
             return `data:image/jpeg;base64,${imagen.img}`;
            })
            setImagesSRCs(imgSRCs);
        }
    }, [devolucion, imagenes]);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    
    const getImages = async (devolucionId) => {
        const res = await getImgsDevolucionMercancias(devolucionId);
        if(res.success) {
            return setImagenes(res.data);
        }
        toast.error('Error al obtener las imágenes.')
        setImagenes([]);
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="d-flex flex-column">
                <small>
                    #<b className="me-2">{devolucion.pieza.id_referencia_plataforma}</b> 
                    {devolucion.pieza.nombre}
                </small>
            </div>

            <div className="mt-3">
                <label>Observaciones:</label>
                <p>{devolucion.observaciones}</p>
            </div>

            <div className="d-flex flex-column mt-3">
                <label>Imágenes</label>

                <div className="w-100 d-flex flex-wrap align-items-center">
                    { !imagenes
                        ? <CircularProgress size="20px" color='inherit'/>
                        : imagenes?.length > 0 ? imagenes.map(({img}, i) => {
                        return (
                            <img 
                                key={"Imagen " + (i+1)} 
                                src={`data:image/jpeg;base64,${img}`}
                                alt={"Imagen " + (i+1)} 
                                className="mb-4 me-4"
                                width={'200px'}
                                height={'200px'}
                                style={{cursor: 'pointer', objectFit: 'cover'}}
                                onClick={() => handleOpenLightBox(i)}
                            />
                        )
                    }) : <p>No hay imágenes.</p>
                    }
                </div>
            </div>

            {devolucion.comentario_almacen && 
                <div className="mt-3">
                    <label>Comentario para almacén:</label>
                    <p>{devolucion.comentario_almacen}</p>
                </div>
            }

            { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    )
}