
import DateSelector from '../../../../../shared/components/DateSelector';
import moment from 'moment';
import { fetchDesguacesGeneralMetrics, fetchDesguacesMetrics } from '../../../../../../../../redux/features/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import DesguacesMetrics from './components/DesguacesMetrics';
import GeneralMetrics from './components/GeneralMetrics';

export default function DesguacesTab() {
  /** State **/
  const [currentDesguace, setCurrentDesguace] = useState(null);
  const [startDate, setStartDate] = useState(moment().date() >= 26 ?
    moment().date(26).format('YYYY-MM-DD')
    : moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().date() >= 26 ?
    moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    : moment().date(25).format('YYYY-MM-DD'));
  const [view, setView] = useState('variable');

  /** Redux **/
  const dispatch = useDispatch();
  const desguaces = useSelector(state => {
    return state.desguaces
  });
  const dashboard = useSelector(state => {
    return state.dashboard.entity.admin
  });

  /** Lifecycle **/
  useEffect(() => {
    dispatch(fetchDesguacesGeneralMetrics(startDate, endDate, view));
  }, []);

  /** Methods **/
  const handleChangeDesguace = (e) => {
    if (parseInt(e.target.value) === -1) {
      setCurrentDesguace(null);
      dispatch(fetchDesguacesGeneralMetrics(startDate, endDate, view));
    } else {
      setCurrentDesguace(e.target.value);
      dispatch(fetchDesguacesMetrics(e.target.value, startDate, endDate, view));
    }
  }

  const handleDateInterval = (startDate, endDate, view) => {
    setView(view);
    setStartDate(startDate);
    setEndDate(endDate);

    if (!currentDesguace) dispatch(fetchDesguacesGeneralMetrics(startDate, endDate, view));
    else dispatch(fetchDesguacesMetrics(currentDesguace, startDate, endDate, view));
  }

  /** Render **/
  return (
    <div className='w-100 d-flex flex-column align-items-center dashboard'>
      <div className='w-100 mt-4 summary'>
        <h1>Información por desguace</h1>
        <div className='w-100 d-flex align-items-center mb-4'>
          {(desguaces && desguaces.length > 0) &&
            <select
              id="desguace"
              defaultValue={currentDesguace ? currentDesguace : -1}
              onChange={(e) => handleChangeDesguace(e)}>
              <option key={'desguace-' + 0} value={-1}>Todos</option>
              {desguaces.map(op => <option key={'desguace-' + op.id} value={op.id}>{op.nombre}</option>)}
              <option key={'desguace-otros'} value={'otros'}>Otros</option>
            </select>
          }

          <DateSelector onSelectedDate={handleDateInterval} />
        </div>

        {(!currentDesguace && dashboard.desguacesGeneralMetrics) &&
          <GeneralMetrics
            view={view}
            data={dashboard.desguacesGeneralMetrics} />
        }

        {(currentDesguace && dashboard.desguacesMetrics) &&
          <DesguacesMetrics
            view={view}
            currentDesguaces={dashboard.desguacesMetrics} />
        }
      </div>
    </div>
  )
}