import ActionsMenu from "../shared/components/ActionsMenu";
import ConfirmModal from "../shared/components/ConfirmModal";
import InfoModal from "../shared/components/InfoModal";
import Loading from "../shared/components/Loading";
import NuevaReservaForm from "./components/NuevaReservaForm";
import Searcher from "./components/Searcher";
import moment from "moment";
import { getActions } from "./actions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
  fetchPiezasReservadas,
  addReserva,
  updateReserva,
} from "../../../../redux/piezasReservadas";

export default function PiezasReservadas() {
  /** Variables **/
  const estadoPiezaValues = [
    { key: 'todos', value: 'todos', label: 'Todos' },
    { key: 'no', value: 'no', label: 'No' },
    { key: 'si', value: 'si', label: 'Si' },
  ];

  /** State **/
  const [openReservarPieza, setOpenReservarPieza] = useState(false);
  const [openConfirmarQuitar, setOpenConfirmarQuitar] = useState(false);
  const [currentReserva, setCurrentReserva] = useState(null);
  const [
    filteredPiezasReservadasEntities,
    setFilteredPiezasReservadasEntities,
  ] = useState([]);
  const [buscador, setBuscador] = useState('');
  const [estadoPieza, setEstadoPieza] = useState('no');

  /** Redux **/
  const dispatch = useDispatch();
  const piezasReservadas = useSelector((state) => {
    const { piezas_reservadas } = state;
    return piezas_reservadas;
  });

  /** Lifecycle **/
  useEffect(() => {
    dispatch(fetchPiezasReservadas());
  }, []);
  useEffect(() => {
    setFilteredPiezasReservadasEntities(piezasReservadas.entities);
    filterPiezasReservadas(buscador, estadoPieza);
  }, [piezasReservadas]);

  /** Methods **/
  const filterPiezasReservadas = (searchTerm, estado) => {
    const searchQuery = searchTerm?.toLowerCase() || '';

    const filteredEntities = piezasReservadas.entities.filter((pieza) => {
      let matchesEstado = false;

      if (estado === 'todos') {
        matchesEstado = true;
      } else if (estado === 'si') {
        matchesEstado = pieza.vendida;
      } else {
        matchesEstado = !pieza.vendida;
      }

      const matchesSearch = pieza.id?.toLowerCase().includes(searchQuery);

      return matchesEstado && matchesSearch;
    });

    setFilteredPiezasReservadasEntities(filteredEntities);
  };
  const handleReservarPieza = (data) => {
    setOpenReservarPieza(false);
    dispatch(addReserva(data));
    dispatch(fetchPiezasReservadas());
  };
  const handleAction = (action, id) => {
    if (action === "remove") {
      handleQuitarButtonPress(id);
    }
  };
  const handleQuitarButtonPress = (id) => {
    setOpenConfirmarQuitar(true);
    setCurrentReserva(id);
  };
  const handleQuitarReserva = (confirmed) => {
    setOpenConfirmarQuitar(false);
    if (!confirmed) return;
    dispatch(updateReserva(currentReserva));
    dispatch(fetchPiezasReservadas());
  };

  /** Render **/
  return (
    <>
      <div className="content pt-5">
        <h1 className="page-name">Piezas reservadas</h1>
        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="d-flex align-items-center justify-content-between mb-3 w-100 flex-wrap gap-2">
            <div className="d-flex align-items-end gap-2 flex-wrap">
              <div>
                <small>Vendida</small>
                {(estadoPiezaValues?.length > 0) && (
                  <select
                    id="estadoPieza"
                    value={estadoPieza}
                    onChange={(e) => {
                      setEstadoPieza(e.target.value);
                      filterPiezasReservadas(buscador, e.target.value);
                    }}
                  >
                    {estadoPiezaValues?.map(estado => (
                      <option key={estado.key} value={estado.value}>
                        {estado.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <Searcher
                onSearch={({ buscador }) => {
                  setBuscador(buscador);
                  filterPiezasReservadas(buscador, estadoPieza);
                }}
              />
            </div>
            <button
              className="btn btn-info me-3"
              onClick={() => setOpenReservarPieza(true)}
            >
              Nueva reserva
            </button>
          </div>

          {filteredPiezasReservadasEntities?.length ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Id referencia plataforma</th>
                    <th>Pieza</th>
                    <th>Vendida</th>
                    <th>Referencia</th>
                    <th>Recambista</th>
                    <th>Fecha</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredPiezasReservadasEntities?.map((pieza) => {
                    return (
                      <tr key={pieza.id}>
                        <td>{pieza.id}</td>
                        <td>{pieza.nom}</td>
                        <td>
                          <span className={"fw-bold " + (pieza.vendida ? 'text-green' : 'text-danger')}>
                            {pieza.vendida ? 'SI' : 'NO'}
                          </span>
                        </td>
                        <td>{pieza.ref}</td>
                        <td>{pieza.recambista || "-"}</td>
                        <td>{moment(pieza.fecha_mod).format('DD-MM-YYYY')}</td>
                        <td className="text-center">
                          <ActionsMenu
                            options={getActions()}
                            onAction={(action) =>
                              handleAction(action, pieza.id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex items-center w-100">
              {piezasReservadas.status?.loading === "pending" ||
                piezasReservadas.loading === "idle" ? (
                <Loading />
              ) : null}
            </div>
          )}
        </div>
      </div>
      {openReservarPieza && (
        <InfoModal
          state={openReservarPieza}
          title={"Marcar pieza como reservada"}
          content={<NuevaReservaForm onReservarPieza={handleReservarPieza} />}
          width={"sm"}
          onClose={() => setOpenReservarPieza(false)}
        />
      )}
      {openConfirmarQuitar && (
        <ConfirmModal
          state={openConfirmarQuitar}
          title={"Quitar reserva"}
          onConfirmAction={handleQuitarReserva}
          content={"Estas seguro de quitar esta reserva?"}
          width={"sm"}
          onClose={() => setOpenConfirmarQuitar(false)}
        />
      )}
    </>
  );
}
