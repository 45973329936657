import { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { toast } from "react-toastify";
import config from "../../../../../../config";
import { useDispatch } from "react-redux";
import { generatePresupuestoPDF } from "../../../shared/services/presupuestos";
import ConfirmModal from "../../../shared/components/ConfirmModal";

export default function PlantillaEmailModal({ plantillas, presupuesto }) {
  /** Redux **/
  const dispatch = useDispatch();

  /** States **/
  const [currentPlantilla, setCurrentPlantilla] = useState(null);
  const [updatedPlantillas, setUpdatedPlantillas] = useState([]);
  const [piezasTotales, setPiezasTotales] = useState(0);
  const [imagenesTotales, setImagenesTotales] = useState(0);
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);

  /** Lifecycle **/
  useEffect(() => {
    let imagenes = 0;
    let piezas = 0;
    presupuesto.piezas?.forEach((pieza) => {
      const images = pieza?.integracion_imgs;
      if (images?.length > 1) {
        imagenes += images.length;
      } else {
        piezas++;
      }
    });
    setPiezasTotales(piezas);
    setImagenesTotales(imagenes);
  }, [presupuesto]);

  /** Effects **/
  useEffect(() => {
    setUpdatedPlantillas(
      plantillas.map((plantilla) => {
        return {
          ...plantilla,
          contenido: plantilla?.contenido
            ?.replaceAll("$PRESUPUESTO", presupuesto.numero || "[$PRESUPUESTO]")
            .replaceAll("$TOTAL_SIN_IVA", presupuesto.total || "0")
            .replaceAll("$ENVIO", presupuesto.total_envio || "0")
            .replaceAll("$TOTAL_CON_IVA", presupuesto.total_iva || "0")
            .replaceAll(
              "$LINK_CHECKOUT",
              config.recomotor.clientsUrl +
              "checkout/" +
              presupuesto.checkout_id || "[$LINK_CHECKOUT]"
            )
            .replaceAll(
              "$CREATOR_NAME",
              presupuesto.created_by?.username
                ? presupuesto.created_by.username
                  .split("_")
                  .map((part) => part[0].toUpperCase() + part.slice(1))
                  .join(" ")
                : "[$USER_NAME]"
            )
            .replaceAll("$CASCO", presupuesto.total_casco || "0"),
        };
      })
    );
  }, []);

  /** Methods **/
  const copyTemplateToClipboard = () => {
    navigator.clipboard.writeText(currentPlantilla);
    toast.success("Plantilla copiada!");
  };

  const handleConfirmAbrirImagenes = (confirmed) => {
    setConfirmModalOpened(false);
    if (!confirmed) {
      return;
    }
    abrirImagenes(presupuesto);
  };

  const abrirImagenes = async (presupuesto) => {
    presupuesto.piezas?.forEach(async (pieza) => {
      const images = pieza.integracion_imgs;
      if (images?.length > 1) {
        images?.forEach(async (image) => {
          window.open(image, "_blank");
        });
      } else {
        window.open(pieza.url, "_blank");
      }
    });
  };

  const generarPDF = async (presupuesto) => {
    dispatch({ type: "loading/set", payload: true });
    const pdfResult = await generatePresupuestoPDF(presupuesto.id).finally(() =>
      dispatch({ type: "loading/set", payload: false })
    );

    if (pdfResult) {
      toast.success("PDF generado correctamente!");
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", presupuesto.numero + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Ups! Algo ha ido mal...");
    }
  };

  /** Render **/
  return (
    <div>
      {updatedPlantillas?.map((plantilla) => (
        <Accordion
          onChange={() => setCurrentPlantilla(plantilla.contenido)}
          key={plantilla.id}
        >
          <AccordionSummary
            aria-controls="panel1-content"
            id="mejoras-metricas"
          >
            <strong
              style={{
                color: "#215731",
                position: "absolute",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "90%",
                top: "1rem",
              }}
            >
              {plantilla.nombre}
            </strong>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex flex-column">
              <textarea
                rows={15}
                defaultValue={plantilla.contenido}
                onChange={(e) => setCurrentPlantilla(e.target.value)}
              />
              <div className="d-flex mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => generarPDF(presupuesto)}
                >
                  PDF
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  onClick={() => setConfirmModalOpened(true)}
                >
                  Imagenes
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={copyTemplateToClipboard}
                >
                  Copiar
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      {confirmModalOpened && (
        <ConfirmModal
          onConfirmAction={handleConfirmAbrirImagenes}
          onCancelAction={() => setConfirmModalOpened(false)}
          title={"Imagenes"}
          description={
            imagenesTotales === 1 || piezasTotales === 1
              ? `
            Se abrirá la ${imagenesTotales ? "imagen" : "pieza"
              } en una nueva pestaña. ¿Desea continuar?
            `
              : `
            Se abrirán las ${imagenesTotales || piezasTotales} ${imagenesTotales ? "imagenes" : "piezas"
              } en nuevas pestañas. ¿Desea continuar? `
          }
          state={confirmModalOpened}
        ></ConfirmModal>
      )}
    </div>
  );
}