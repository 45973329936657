import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faExclamationCircle, faPortrait } from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip, isCanariasCeutaOrMelillaOrBaleares } from "../../helpers/functionalities";

export default function ProveedorCard({
    proveedor,
    desguaceInfo
}) {
    const [hasWarning, setHasWarning] = useState(false);
    const [currentProveedor, setCurrentProveedor] = useState(proveedor);

    useEffect(() => {
        if (desguaceInfo) {
            const updatedProveedor = {
                ...proveedor,
                nombre: desguaceInfo.nombre,
                cif: desguaceInfo.cif,
                telefono: desguaceInfo.telefono,
                email: desguaceInfo.email,
                direccion: desguaceInfo.direccion,
                codigo_postal: desguaceInfo.codigo_postal,
                poblacion: desguaceInfo.poblacion,
                area: desguaceInfo.provincia,
                pais: { ...proveedor.pais, nombre: desguaceInfo.pais }
            };
    
            setCurrentProveedor(updatedProveedor);
        }
    }, [desguaceInfo]);

    useEffect(() => {
        const {codigo_postal, poblacion, pais_facturacion_id} = proveedor;

        if(pais_facturacion_id) {
            if(pais_facturacion_id === 1 && (codigo_postal && poblacion)) {
                if(isCanariasCeutaOrMelillaOrBaleares(parseInt(proveedor.codigo_postal))) setHasWarning(true); 
            } else { setHasWarning(true); }
        }
      
    }, [proveedor]);
    
    return (
        <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-between custom-card">
           <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between">
                <div className="d-flex align-items-start">
                    <div className="d-flex flex-column me-3">
                        <span className="icon">
                            <FontAwesomeIcon icon={faPortrait} size="1x" color="#215732" />
                        </span>
                    </div>

                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-1">
                            <h3 className="text-success">{currentProveedor?.nombre}</h3>

                            { (hasWarning) ?
                                <CustomTooltip
                                    title={
                                        <React.Fragment>
                                            <small className="m-0">Atención! Cliente de Baleares, Canarias, Ceuta, Melilla o fuera de España.</small>
                                        </React.Fragment>
                                    }
                                >
                                    <span className="align-self-start me-2" style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="action" color="#ffc107" /> 
                                    </span>
                                </CustomTooltip>
                                : ''
                            }
                        </div>
                    

                        <p className="m-0 text-uppercase">CIF: {currentProveedor?.cif ? currentProveedor?.cif : '-'}</p>
                        <p>Teléfono: {currentProveedor.telefono}</p>
                        <p>Email: {currentProveedor.email}</p>

                        <div className="d-flex flex-wrap">
                            { (currentProveedor?.credito) ?
                                <small className="fw-bold text-uppercase">A crédito</small>
                                : ''
                            }
                            { (currentProveedor?.credito && currentProveedor?.integracion) ? <p className="mx-2">|</p> : ''}
                            { (currentProveedor?.integracion) ?
                                <small className="fw-bold text-uppercase">Integrado</small>
                                : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="w-100 d-flex align-items-center flex-wrap">
                        <label>SCORE: &nbsp;</label>
                        <p className={"fw-bold " + (currentProveedor.score >= 7 ? 'text-green' : currentProveedor.score >= 6.5 ? 'text-warning' : 'text-danger')}>
                            {currentProveedor.score ? currentProveedor.score : '-'}
                        </p>
                    </div>  
                        
                    { (currentProveedor.descuento === 'fijo' || currentProveedor.descuento === 'variable') &&
                        <div>
                            <div className="w-100 d-flex align-items-center flex-wrap">
                                <label>Descuento: &nbsp;</label>
                                <p>{currentProveedor.descuento ? <b className="text-success text-uppercase">{currentProveedor.descuento}</b> : ''}</p>
                            </div>

                            <div>
                                { currentProveedor.descuentos.map(descuento => {
                                    return (
                                        <div>
                                            <p className="cliente-card-small">
                                                {descuento.min} - {descuento.max ? descuento.max : 'sin máximo'} = {descuento.descuento} %
                                            </p>
                                            <div className="w-100 d-flex align-items-center">
                                                { descuento.tipo_piezas.length > 0 &&
                                                    descuento.tipo_piezas.map((tp, i) => {
                                                        return (
                                                        <span className="cliente-card-small"> 
                                                                {tp.nombre} 
                                                                {(i >= 0 && i < descuento.tipo_piezas.length -1) ? 
                                                                <span className="mx-2">|</span>
                                                                : ''
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    }
                </div>
                
                <div className="d-flex flex-column">
                    <label>Dirección:</label>
                    <p>{currentProveedor?.direccion}</p>
                    <p>{(currentProveedor.codigo_postal + ' ' + currentProveedor.poblacion + ", " + currentProveedor.area)}, {currentProveedor.pais_id ? currentProveedor.pais.nombre : ''}</p>
                </div>

                <div className="d-flex flex-column">
                    <label>Administracion</label>
                    <p>Nombre: {currentProveedor.persona_administracion ? currentProveedor.persona_administracion : '-'}</p>
                    <p>Teléfono: {currentProveedor.telefono_administracion ? currentProveedor.telefono_administracion : '-'}</p>
                    <p>Email: {currentProveedor.email_administracion ? currentProveedor.email_administracion : '-'}</p>
                </div>

                <div className="d-flex flex-column">
                    <label>Recambios</label>
                    <p>Nombre: {currentProveedor.persona_recambios ? currentProveedor.persona_recambios : '-'}</p>
                    <p>Teléfono: {currentProveedor.telefono_recambios ? currentProveedor.telefono_recambios : ''}</p>
                    <p>Email: {currentProveedor.email_recambios ? currentProveedor.email_recambios : '-'}</p>
                    
                </div>

                <div className="d-flex flex-column">
                    <label>Incidencias</label>
                    <p>Nombre: {currentProveedor.persona_incidencias ? currentProveedor.persona_incidencias : '-'}</p>
                    <p>Teléfono: {currentProveedor.telefono_incidencias ? currentProveedor.telefono_incidencias : '-'}</p>
                    <p>Email: {currentProveedor.email_incidencias ? currentProveedor.email_incidencias : '-'}</p>
                </div>
            </div>
        </div>
    )
}