import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import VirtualizedAutocompleteDirecciones from "../../../../../shared/components/AutocompleteDirecciones";
import { handleSetDireccion, isAddressOutOfRange } from "../../../../../shared/helpers/functionalities";
import GooglePlacesAutocomplete from "../../../../../shared/components/GooglePlacesAutocomplete";
import { useWatch } from "react-hook-form";

export default function ContactoForm({
    data,
    options,
    register,
    setValue,
    getValues,
    control,
    titulo,
    tipo,
    onDisable
}) {
    const columns = [ 
        "nombre", "telefono", "email", 
        "direccion", "poblacion", "codigo_postal", 
        "area", "pais_id" , "data",
        "entity_id", "direccion_envio_id", "proveedor_id",
        "almacen_id", "remitente_id", "destinatario_id",
        "tipo_cliente_id"
    ];
    const [disabledSearcher, setDisabledSearcher] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const watchedValues = useWatch([
        tipo + "_pais_id"
    ]);
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });

    useEffect(() => {
    }, [data, options]);

    const handleChangePais = (e) => { setValue(tipo + '_pais_id', e.target.value); }

    const handleChangeTipo = (e) => {
        const value = e.target.value;
        setValue(tipo + '_tipo', value);
        resetValues();

        if(value === 'buscar') {
            setDisabled(true);
            setDisabledSearcher(false);
        } else {
            setDisabled(false);
            setDisabledSearcher(true);
        }
    }

    const handleChangeTipoCliente = (e) => {
        const value = parseInt(e.target.value);
        setValue(tipo + "_tipo_cliente_id", value);
    }

    const handleChangeData = (data) => {
        if(data) {
            if(isAddressOutOfRange(data.codigo_postal)) {
                onDisable(true);
            } else onDisable(false);

           columns.forEach(function(key) {
                setValue((tipo + '_' + key), data[key])
            });
        } else resetValues();
    }

    const resetValues = () => {
        columns.forEach(function(key) {
            setValue((tipo + '_' + key), key === 'data' ? null : key === 'pais_id' ? 1 : '')
        });
    }

    const handleChangeDireccion = (direccion) => {
        const newValues = handleSetDireccion(direccion, 'envio-' + tipo)
        Object.keys(newValues).forEach(function(key) {
            setValue(key, newValues[key])
        });
    }

    return (
        <div className="w-100 d-flex flex-column">
            <h5>{titulo}</h5>

            <div className="w-100 d-flex flex-column">
                <div className="form-group me-3">
                    <div className="mb-2">
                        <input
                            {...register(tipo + "_tipo", { required: true })}
                            type="radio"
                            value={"manual"}
                            id={tipo + "_tipo"}
                            onChange={handleChangeTipo}
                        />
                        &nbsp; <b>Rellenar datos manualmente</b>
                    </div>

                    <div>
                        <input
                            {...register(tipo + "_tipo", { required: true })}
                            type="radio"
                            value={"buscar"}
                            id={tipo + "_tipo"}
                            onChange={handleChangeTipo}
                        />
                        &nbsp; <b>Buscar por nombre/empresa</b>
                    </div>
                </div>

                <div className="w-100">
                    <VirtualizedAutocompleteDirecciones
                        id={tipo + "_data"}
                        control={control}
                        options={options?.sort((a, b) => -b.nombre.localeCompare(a.nombre))}
                        labels={['nombre', 'telefono']}
                        defaultValue={null} 
                        disabled={disabledSearcher}
                        onChange={handleChangeData}
                    />
                </div>

                <div className="w-100 d-flex flex-column mt-3">
                    <input type="hidden" 
                        {...register(tipo + "_entity_id")} 
                        defaultValue={data ? data[tipo].entity_id : ''} />
                    <input type="hidden" 
                        {...register(tipo + "_direccion_envio_id")} 
                        defaultValue={data ? data[tipo].direccion_envio_id : ''} />
                    <input type="hidden" 
                        {...register(tipo +"_proveedor_id")} 
                        defaultValue={data ? data[tipo].proveedor_id : ''} />
                    <input type="hidden" 
                        {...register(tipo +"_almacen_id")} 
                        defaultValue={data ? data[tipo].almacen_id : ''} />
                    <input type="hidden" 
                        {...register(tipo +"_remitente_id")} 
                        defaultValue={data ? data[tipo].remitente_id : ''} />
                    <input type="hidden" 
                        {...register(tipo +"_destinatario_id")} 
                        defaultValue={data ? data[tipo].destinatario_id : ''} />
                    
                    <div className="form-group">
                        <label>Nombre/Empresa</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            disabled={disabled}
                            {...register(tipo + "_nombre", { required: true })} 
                            defaultValue={''} />
                    </div>

                    {
                        !disabled && tipo === 'destinatario' && 
                            <div className="form-group">
                                <label htmlFor={tipo + "_tipo_cliente_id"}>Tipo {tipo}</label>
                                    <select 
                                        {...register(tipo + "_tipo_cliente_id", {required: true})}
                                        defaultValue={0}
                                        onChange={handleChangeTipoCliente}
                                    >
                                       <option value={1}>Particular</option>
                                       <option value={2}>Empresa</option>
                                    </select>
                            </div>
                    }

                    <div className="w-100 d-flex align-items-center">
                        <div className="form-group me-3">
                            <label>Teléfono</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                disabled={disabled}
                                {...register(tipo + "_telefono", { required: true })} 
                                defaultValue={''} />
                        </div>

                        <div className="form-group">
                            <label>Email</label>
                            <input 
                                type="text" 
                                disabled={disabled}
                                className="form-control" 
                                {...register(tipo + "_email", { required: true })} 
                                defaultValue={''} />
                        </div>
                    </div>
                   

                    <div className="w-100 d-flex align-items-center">
                        <div className="d-flex flex-column mb-3 me-3">
                            <label htmlFor={tipo + "_pais_id"} className="mb-1">País</label>
                            { (paises) &&
                                <select 
                                    {...register(tipo + "_pais_id", {required: true})}
                                    defaultValue={1}
                                    disabled={disabled}
                                    onChange={handleChangePais}>
                                    {paises.map(op => <option key={'paises-fact-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }
                        </div>

                        <div className="form-group me-3">
                            <label>Busca una dirección</label>
                            <GooglePlacesAutocomplete 
                                type={'address'}
                                types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                country={paises?.find(p => p.id === parseInt(getValues(tipo + "_pais_id")))?.codigo || 'ES'}
                                onSelectResult={handleChangeDireccion}
                                disabled={disabled}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column mb-3 me-3">
                             <label htmlFor={tipo + "_codigo_postal"}>Código postal</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                disabled={disabled}
                                {...register(tipo + "_codigo_postal", { required: true })} 
                                defaultValue={''} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor={tipo + "_direccion"}>Dirección</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                disabled={disabled}
                                {...register(tipo + "_direccion", { required: true })} 
                                defaultValue={''} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="form-group me-3">
                            <label htmlFor={tipo + "_poblacion"}>Población</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                disabled={disabled}
                                {...register(tipo + "_poblacion", { required: true })} 
                                defaultValue={''} />
                        </div>

                        <div className="form-group">
                            <label htmlFor={tipo + "_area"}>Área (Provincia, CCAA)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                disabled={disabled}
                                placeholder="Provincia, CCAA"
                                {...register(tipo + "_area", { required: true })} 
                                defaultValue={''} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}