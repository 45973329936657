import React, { useEffect } from 'react';
import { DoughnutChart } from '../../../../../../../shared/components/Charts/components/Doughnut';
import VerticalBarChart from '../../../../../../../shared/components/Charts/components/VerticalBar';
import MetricsTable from '../../../shared/MetricsTable';

export default function ClientesMetrics({view, data}) {

    useEffect(() => {
        
    }, [data, view]);

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-center'>
                <div className='col-12 col-md-5 pe-0 pe-md-3'>
                    { data?.tipo_clientes_chart ?
                        <DoughnutChart
                            title={'Clientes'}
                            dataset={data?.tipo_clientes_chart}
                        />
                        : (
                            <div className='d-flex flex-column align-items-center justify-content-center gap-1'>
                                <span style={{color: 'grey', fontSize: '12px'}} className='fw-bold'>Clientes</span>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        )
                    }
                </div>

                <div className='col-12 col-md-5 pe-0 pe-md-3'>
                    { data?.clientes_por_pais ?
                        <DoughnutChart
                            title={'Clientes por país'}
                            dataset={data?.clientes_por_pais}
                        />
                        : (
                            <div className='d-flex flex-column align-items-center justify-content-center gap-1'>
                                <span style={{color: 'grey', fontSize: '12px'}} className='fw-bold'>Clientes por país</span>
                                <p className='text-center'>No hay datos.</p>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between mt-5'>
                <div className='col-12 pe-0 pe-md-3 d-flex align-items-center justify-content-center'>
                    { (view !== 'año' && view !== 'año-variable' && view !== 'ultimos-12meses') ? 
                        <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                            <div className='w-100 w-md-100 card-item d-flex flex-column align-items-center'>
                                Talleres que han comprado +2 veces en los últimos 60 dias
                                <h1>{data.clientes_b2b_recurrentes}</h1>
                            </div>
                        </div>
                        :  
                        (typeof data?.clientes_b2b_recurrentes === 'object') ?
                            <VerticalBarChart 
                                title={'Talleres que han comprado +2 veces en los últimos 60 dias'} 
                                dataset={data.clientes_b2b_recurrentes}
                            />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
            </div>

            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_clientes ?
                        <MetricsTable 
                            titulo={"Top 5 Clientes anual"}
                            labels={['cliente', 'ultima venta', 'ventas', 'presupuestos', 'ticket medio']}
                            data={data.top_clientes}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_clientes_view ?
                        <MetricsTable 
                            titulo={"Mejores Clientes"}
                            labels={['cliente', 'ultima venta', 'ventas', 'presupuestos', 'ticket medio']}
                            data={data.top_clientes_view}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
            </div>

             <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_ratio_conversion ?
                        <MetricsTable 
                            titulo={"Top ratio de conversión"}
                            labels={['cliente', 'presupuestos', 'ventas', 'ratio']}
                            data={data.top_ratio_conversion}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.low_ratio_conversion ?
                        <MetricsTable 
                            titulo={"Menor ratio conversión"}
                            labels={['cliente', 'presupuestos', 'ventas', 'ratio']}
                            data={data.low_ratio_conversion}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
             </div>
             
             <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.ultimas_ventas ?
                        <MetricsTable 
                            titulo={"Últimas ventas"}
                            labels={['cliente', 'fecha', 'importe']}
                            data={data.ultimas_ventas}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
             </div>
        </div>
    )
}