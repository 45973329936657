// LOAD ALL THE READ ONLY DATA
import { getPlantillasEmail } from "../views/Dashboard/components/shared/services/plantillasEmail";
import {
  makeFetchingReducer,
  makeAsyncTypes,
  asyncMac,
  makeSetReducer,
} from "./utils";

const asyncTipos = makeAsyncTypes("plantillas_email");

const [setPending, setFullFilled, setError] = asyncMac(asyncTipos);

export const plantillasEmailFullfilledReducer = makeSetReducer([
  "plantillas_email/fullfilled",
]);

export const fetchingReducer = makeFetchingReducer([
  "plantillas_email/pending",
  "plantillas_email/fullfilled",
  "plantillas_email/rejected",
]);

export const fetchPlantillasEmail = () => async (dispatch) => {
  dispatch(setPending());

  try {
    const response = await getPlantillasEmail()
    const data = await response.data;
    dispatch({ type: "plantillas_email/fullfilled", payload: data });
    setFullFilled(data);
    const readOnly = JSON.parse(localStorage.getItem('read_only'));
    readOnly.plantillas_email = data;
    localStorage.setItem('read_only', JSON.stringify(readOnly));
  } catch (e) {
    dispatch(setError(e.message));
  }
};