import { useState, useEffect } from "react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { toast } from "react-toastify";
import moment from 'moment';
import Header from '../shared/components/Header'
import { faPen, faCodeBranch, faCheck, faTimes, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { fetchProveedores } from "../../../../redux/proveedores";
import InfoModal from "../shared/components/InfoModal";
import ExportForm from "./components/ExportForm";
import { comprasExport, fusionarProveedores, newProveedor, updateProveedor, updateProveedorPost } from "../shared/services/proveedores";
import FusionForm from "./components/FusionForm";
import { isBoolean, setErrorMessage } from "../shared/helpers/functionalities";
import Loading from "../shared/components/Loading";
import ProveedorModal from "../shared/components/ProveedorModal";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "../Clientes/components/EntityTable/actions";

export default function Proveedores() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  const user = JSON.parse(localStorage.getItem('user'));
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isFusion, setIsFusion] = useState(false);
  const currentProveedor = useSelector(state => {
    return state.currentProveedor
  });
  const proveedores = useSelector(state => {
    return state.proveedores.entities
  });
  const proveedoresStatus = useSelector(state => {
    return state.proveedores.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchProveedores(currPage,  getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
        + (isBoolean(searcher?.credito) ? '&credito=' + searcher?.credito : '')
        + (isBoolean(searcher?.integracion) ? '&integracion=' + searcher?.integracion : '')
      : null;

    return query;
  }

  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/proveedores/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/proveedores/${newPage}`);
    dispatch(fetchProveedores(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = proveedores.total_items < 15 ? 1 : Math.ceil(proveedores.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, proveedor) => {
    if(action === 'edit') {
      handleEdit(proveedor)
    } else  if(action === 'fusion') {
      handleOpenFusion(proveedor)
    }
  }

  const handleMakeExport = () => { setIsExport(true); }
  const handleCloseExport = () => { setIsExport(false); }
  const handleExport = async(data) => {
    const exportResult = await comprasExport(data);

    if(exportResult) {
      const start = moment(data.startDate).format('DD-MM-YYYY');
      const end = moment(data.endDate).format('DD-MM-YYYY');

      toast.success('Listado de compras descargado correctamente!')
      setIsExport(false);
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'proveedores-' + start + '-' + end + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenFusion = (proveedor) => { 
    dispatch({type: "proveedor/fullfilled", payload: proveedor});
    setIsFusion(true); 
  }
  const handleCloseFusion = () => setIsFusion(false);
  const handleFusion = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await fusionarProveedores(data).catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
    });
    
    if(response && response.success) { 
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchProveedores(currPage,  getQuery()));
      setIsFusion(false);
    }
  }

  const handleCloseProveedorForm = () => { 
    setIsOpenForm(false); 
    dispatch({type: "proveedor/clear", payload: null});
  }
  const handleNewProveedor = () => { 
    setIsOpenForm(true); 
    dispatch({type: "proveedor/clear", payload: null});
  }
  const handleEdit = (proveedor) => { 
    setIsOpenForm(true); 
    dispatch({type: "proveedor/fullfilled", payload: proveedor});
  }

  const handleSubmitProveedor = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    let response;

    if(currentProveedor) {
      response = await updateProveedor(data)
        .catch((e) => console.error(`Update provider failed: ${e}`))
        .finally(() => {
          dispatch({type: 'loading/set', payload: false});
        });
    } else {
      response = await newProveedor(data)
        .catch((e) => console.error(`Add new provider failed: ${e}`))
        .finally(() => {
          dispatch({type: 'loading/set', payload: false});
        });
    }

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsOpenForm(false);
      dispatch({type: "proveedor/clear", payload: null});
      dispatch(fetchProveedores(currPage,  getQuery()));
    } else {
      toast.error('ERROR! Proveedor duplicado o datos incorrectos');
    }
  }

  if(proveedoresStatus.loading === 'pending' || proveedoresStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(proveedoresStatus.loading === 'succeed' || proveedoresStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header
          onNew={() => handleNewProveedor(null)}
          viewSearch={true} 
          onSearch={handleSearch}
          onExport={handleMakeExport}
        ></Header>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">score</th>
                  <th className="text-center">integrado</th>
                  <th className="text-center">credito</th>
                  <th>nombre</th>
                  <th>teléfono</th>
                  <th>email</th>
                  <th>dirección</th>
                  <th>población</th>
                  <th className="text-center">país</th>
                  <th className="text-center">descuentos</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { proveedores.proveedores?.map((proveedor) => {
                  return (
                    <tr key={proveedor.id}>
                      <td className="text-center">
                        <small className={"fw-bold " + (proveedor.score >= 7 ? 'text-green' : proveedor.score >= 6.5 ? 'text-warning' : 'text-danger')}>
                          {proveedor.score ? proveedor.score : '-'}
                        </small>
                      </td>
                      <td className="text-center">
                        <small className={"fw-bold " + (proveedor.integracion ? 'text-green' : 'text-danger')}>
                          {proveedor.integracion ? 
                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                            : <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                          }
                        </small>
                      </td>
                      <td className="text-center">
                        {proveedor.credito ? 
                          <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                          : <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                        }
                      </td>
                      <td width="14%">
                        <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + proveedor.id} title="Ficha Proveedor" className="text-green">
                         { proveedor.bloqueado ? 
                          <span className="fw-bold text-danger">
                            <FontAwesomeIcon icon={faBan} size="1x" className="action" color="#dc3545" />
                            {proveedor.nombre}
                          </span>
                          : proveedor.nombre
                         }
                        </Link>
                      </td>
                      <td width="6%">{proveedor.telefono}</td>
                      <td width="12%">{proveedor.email}</td>
                      <td>{proveedor.direccion}</td>
                      <td width="11%" className="text-uppercase">{(proveedor.codigo_postal + " - " + proveedor.poblacion)}</td>
                      <td className="text-center text-uppercase">{proveedor.pais_id ? proveedor.pais.nombre : ''}</td>
                      <td className="text-center" width="15%">
                        { (proveedor?.descuentos && proveedor?.descuentos?.length > 0) && 
                          <table className="w-100">
                            <thead>
                              <tr>
                               { proveedor?.descuentos.find(d => d.max !== null) && <th>RANGO</th>}
                                <th>PORCENTAJE</th>
                                <th>TIPO PIEZA</th>
                              </tr>
                            </thead>
                            <tbody>
                            { proveedor?.descuentos?.map((descuento, i) => {
                              return (
                                <tr className="border-light" key={'descuento-' + proveedor.id + '-' + i}>
                                  { proveedor?.descuentos.find(d => d.max !== null) && <td>{descuento.max ? (descuento.min + ' - ' + descuento.max + '€') : ''}</td>}
                                  <td>{descuento.descuento}%</td>
                                  <td>
                                    { (descuento?.tipo_piezas && descuento?.tipo_piezas?.length > 0) ?
                                     descuento?.tipo_piezas?.map((tipo) => {
                                        return (
                                          <span>{tipo.nombre}</span>
                                        )
                                    })
                                    : '-'
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                            </tbody>
                          </table>
                        }
                      </td>
                      <td className="text-center">
                        <ActionsMenu 
                            options={getActions(user)}
                            onAction={(action) => handleAction(action, proveedor)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { (isOpenForm) &&
          <ProveedorModal
            open={isOpenForm}
            proveedor={currentProveedor}
            fromPresupuesto={false}
            isEdit={currentProveedor ? true : false}
            onSubmitProveedor={handleSubmitProveedor}
            onClose={handleCloseProveedorForm}
          />
        }

        { isExport && 
          <InfoModal
            state={isExport}
            title="Exportar compras a proveedores a Excel"
            content={<ExportForm dateFilter={true} onSubmitExport={handleExport} />}
            onClose={handleCloseExport}
          ></InfoModal>
        }

        { isFusion && 
          <InfoModal
            width={'lg'}
            state={isFusion}
            title=""
            content={
              <FusionForm 
                proveedor={currentProveedor}
                onSubmitFusion={handleFusion}
               />
            }
            onClose={handleCloseFusion}
          ></InfoModal>
        }
      </div>
    )
  }
}

