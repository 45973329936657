import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import GooglePlacesAutocomplete from "../../../GooglePlacesAutocomplete";
import { handleSetDireccion } from "../../../../helpers/functionalities";

export default function ClienteForm({
    cliente,
    telefono,
    onSubmitClient,
}) {
    const clienteSchema = yup.object({
        nombre: yup.string().nullable(true),
        cif: yup.string().nullable().transform((_, val) => val ? val : null) ,
        telefono: yup.string().nullable().required("El teléfono es obligatorio"),
        email: yup.string().nullable(),
        direccion_facturacion:  yup.string().nullable(),
        codigo_postal_facturacion: yup.string().nullable(),
        poblacion_facturacion: yup.string().nullable(),
        area_facturacion: yup.string().nullable(),
        pais_facturacion_id: yup.number().nullable().integer()
    }).required();

    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue, 
        getValues,
        reset, 
        watch,
        setError,
    } = useForm({
            mode: "onChange",
            resolver: yupResolver(clienteSchema),
            defaultValues: {
                id: '',
                nombre: '',
                email: '',
                cif: '',
                telefono: telefono ? telefono : '',
                tipo_cliente_id: 1,
                tipo_cliente_b2b_id: 1,
                tarifa_cliente_id: 1,
                direccion_facturacion: '',
                codigo_postal_facturacion: '',
                poblacion_facturacion: '',
                area_facturacion: '',
                pais_facturacion_id: 1,
            }
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const tipoClientesB2b = useSelector(state => {
        const { tipo_clientes_b2b } = state;
        return tipo_clientes_b2b;
    });
    const tarifas = useSelector(state => {
        const { tarifas } = state;
        return tarifas;
    });
    const [ currentCliente, setCurrentCliente ] = useState({});
    const [ filterTarifas, setFilterTarifas ] = useState(tarifas);
    const [ isB2bClient, setIsB2bClient ] = useState(true);
    const [ isEdit, setIsEdit ] = useState(false);
 
    useEffect(() => {
        if(telefono) setValue('telefono', telefono);

        if(cliente) {
            setIsEdit(true);
            setCurrentCliente(cliente);
            setIsB2bClient(cliente && cliente.tipo_cliente_id === 2 ? true : false);
            reset(cliente);
            if(cliente.albaranes) {setValue('albaranes', cliente.albaranes && (cliente.albaranes === '0' ? false : true))};
        } else resetValues();
    }, [cliente, telefono]);

    const resetValues = () => {
        reset({
            id: '',
            nombre: '',
            email: '',
            cif: '',
            telefono: telefono ? telefono : '',
            tipo_cliente_id: 2,
            tipo_cliente_b2b_id: 1,
            tarifa_cliente_id: 2,
            direccion_facturacion: '',
            codigo_postal_facturacion: '',
            poblacion_facturacion: '',
            area_facturacion: '',
            pais_facturacion_id: 1,
        });
    }

    const handleChangePaisFacturacion = async(e) => {
        const value = parseInt(e.target.value);

        if(value !== getValues('pais_facturacion_id')) {
            setValue('codigo_postal_facturacion', '')
            setValue('direccion_facturacion', '')
            setValue('poblacion_facturacion', '')
            setValue('area_facturacion', '')
        }
        
        setValue('pais_facturacion_id', value);
    }

    const handleChangeTipoClientes = (e) => {
        setValue('tipo_cliente_id', parseInt(e.target.value));

        var t = tarifas.filter(f => f.tipo_cliente_id === parseInt(e.target.value));
        setFilterTarifas(t);

        if(parseInt(e.target.value) === 2) setIsB2bClient(true); 
        else setIsB2bClient(false);
    }

    const handleChangeTipoClientesB2b = (e) => setValue('tipo_cliente_b2b_id', e.target.value);
    const handleChangeTarifa = (e) => setValue('tarifa_cliente_id', parseInt(e.target.value));

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(!checkIfNameIsNotEmail(data.nombre)) return
        if(data.cif_ue && (data.pais_facturacion_id === 1 || data.pais_facturacion_id === '1')) {
            data.cif_ue = false;
        }

        if(!data.credito) {
            data.albaranes = false;
        }

        if(data.tipo_cliente_id) {
            if(parseInt(data.tipo_cliente_id) === 2) data.b2b = true;
            else {
                data.b2b = false;
                data.tipo_cliente_b2b_id = null;
            }
        }

        onSubmitClient(data);
    };

    const handleChangeDireccion = (direccion) => {
        const newValues = handleSetDireccion(direccion, 'cliente');
        Object.keys(newValues).forEach(function(key) {
            setValue(key, newValues[key])
        });
    }

    const checkIfNameIsNotEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = regex.test(email?.trim().toLowerCase())
        if(isEmail) {
            setError('nombre', {type: 'valid', message: 'El nombre no puede ser un email.'})
            return false;
        }
        return true;
    };
    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                <div className="col-12 align-items-center mt-2 mb-4">
                    <div className="w-100 table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td width="5%" className="text-center">
                                        <input 
                                            type="checkbox"
                                            {...register("credito")} 
                                            defaultChecked={false} />
                                    </td>
                                    <td>
                                        <label className="me-3">El cliente paga a crédito?</label>
                                    </td>
                                </tr>
                                { watch('credito') ?
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("con_codigo")}
                                                        style={{marginLeft: '2px'}}
                                                        defaultChecked={isEdit ? cliente?.con_codigo : true}
                                                    />
                                                    <label className="ms-4">Código de autorización</label>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("albaranes")}
                                                        defaultChecked={isEdit ? (cliente?.albaranes ? (cliente?.albaranes === '0' ? false : true) : false) : true}
                                                    />
                                                    <label className="ms-4">Albaranes (por defecto SI)</label>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("mostrar_referencia")}
                                                        defaultChecked={isEdit ? cliente?.mostrar_referencia : false}
                                                    />
                                                    <label className="ms-4">Mostrar referencia? (por defecto NO)</label>
                                                </div>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                    : null
                                }
                                { watch('pais_facturacion_id') !== 1 && watch('pais_facturacion_id') !== '1' ? 
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input
                                                disabled
                                                type="checkbox"
                                                {...register("cif_ue")} 
                                                defaultChecked={cliente?.cif_ue ? true : false} />
                                        </td>
                                        <td>
                                            <label className="me-3">NIF Intracomunitario</label>
                                        </td>
                                    </tr>
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={isEdit ? currentCliente.id : ''} />

                    <div className="w-100 d-flex flex-column">
                        <div className="w-100 d-flex">
                            <div className="w-100 d-flex flex-column mt-2">
                                <h6 className="text-uppercase">Datos de facturación</h6>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="tipo_cliente_id">Tipo cliente</label>
                                        { (tipoClientes && tipoClientes.length > 0) &&
                                            <select 
                                                {...register("tipo_cliente_id", {required: true})}
                                                defaultValue={isEdit ? currentCliente.tipo_cliente_id : 1}
                                                onChange={handleChangeTipoClientes}>
                                                {tipoClientes.map(op => <option key={'tipo-clientes-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>

                                    { isB2bClient &&
                                    <div className="form-group me-3">
                                        <label htmlFor="tipo_cliente_b2b_id">Tipo cliente B2B</label>
                                        { (tipoClientesB2b && tipoClientesB2b.length > 0) &&
                                            <select 
                                                {...register("tipo_cliente_b2b_id", {required: true})}
                                                defaultValue={isEdit ? currentCliente.tipo_cliente_b2b_id : 1}
                                                onChange={handleChangeTipoClientesB2b}>
                                                {tipoClientesB2b.map(op => <option key={'tipo-clientes-b2b-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>
                                    }

                                    <div className="form-group">
                                        <label htmlFor="tarifa_cliente_id">Tarifa</label>
                                        { (filterTarifas) &&
                                            <select 
                                                {...register("tarifa_cliente_id", {required: true})}
                                                defaultValue={isEdit ? currentCliente.tarifa_cliente_id : 1}
                                                onChange={handleChangeTarifa}>
                                                {filterTarifas.map(op => <option key={'tipo-tarifa-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("nombre", { maxLength: 50 })} 
                                            defaultValue={isEdit ? currentCliente.nombre : ''} />
                                        
                                        <div className="form-invalid">
                                            {(errors.nombre) && errors.nombre?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="cif">CIF / NIF</label>
                                        <input
                                            type="text" 
                                            className="form-control" 
                                            maxLength={16}
                                            {...register("cif", { maxLength: 16 })} 
                                            defaultValue={isEdit ? currentCliente.cif : ''} />

                                        <div className="form-invalid">
                                            {(errors.cif) && errors.cif?.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="telefono">Teléfono</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            {...register("telefono", { required: true })} 
                                            defaultValue={isEdit ? currentCliente.telefono : ''} />

                                        <div className="form-invalid">
                                            {(errors.telefono) && errors.telefono?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            {...register("email", {  maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.email : ''} />

                                        <div className="form-invalid">
                                            {(errors.email) && errors.email?.message}
                                        </div>
                                    </div>

                                    { (currentCliente.tipo_cliente_b2b_id !== 2 && currentCliente?.credito && currentCliente?.albaranes) ? (
                                        <div className="form-group ms-3">
                                            <label htmlFor="email">Email albaranes</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                {...register("email_albaranes", {  maxLength: 150 })} 
                                                disabled={watch('bloqueado')}
                                                defaultValue={isEdit ? currentCliente.email_albaranes : ''} />

                                            <div className="form-invalid">
                                                {(errors.email_albaranes) && errors.email_albaranes?.message}
                                            </div>
                                        </div>) : null
                                    }
                                </div>

                                <h6 className="text-uppercase mt-3">Dirección de facturación</h6>

                                <div className="w-100 d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <select 
                                            {...register("pais_facturacion_id", {required: true})}
                                            defaultValue={isEdit ? currentCliente.pais_facturacion_id : 1}
                                            onChange={handleChangePaisFacturacion}>
                                            {paises?.map(op => <option key={'paises-fact-' + op.id} value={op.id}>{op.nombre}</option>)}
                                        </select>

                                        <div className="form-invalid">
                                            {(errors.pais_facturacion_id) && errors.pais_facturacion_id?.message}
                                        </div>
                                    </div>

                                    <GooglePlacesAutocomplete 
                                        type={'address'}
                                        types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                        country={watch('pais_facturacion_id') ? paises?.find(p => p.id === parseInt(getValues('pais_facturacion_id')))?.codigo : 'ES'}
                                        onSelectResult={handleChangeDireccion}
                                   />
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column mb-3 me-3">
                                        <label htmlFor="direccion_facturacion" style={{marginBottom: '5px'}}>Código postal</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("codigo_postal_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.codigo_postal_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.codigo_postal_facturacion) && errors.codigo_postal_facturacion?.message}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="direccion_facturacion">Dirección</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("direccion_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.direccion_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.direccion_facturacion) && errors.direccion_facturacion?.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="direccion_facturacion">Población</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("poblacion_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.poblacion_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.poblacion_facturacion) && errors.poblacion_facturacion?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="direccion_facturacion">Area (Provincia, CCAA)</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Provincia, CCAA"
                                            {...register("area_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.area_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.area_facturacion) && errors.area_facturacion?.message}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}