import { toast } from "react-toastify";
import { 
    getAdminData,
    refreshAdminData,
    getGeneralMetrics, 
    getUserMetrics, 
    getPiezasMetrics, 
    getIncidenciasMetrics,
    getClientesMetrics,
    getProveedoresMetrics,
    getPlataformasUserMetrics,
    getDesguacesGeneralMetrics,
    getDesguacesMetrics,
    getPlataformasGeneralMetrics
} from "../../views/Dashboard/components/shared/services/dashboard";
import { getMensajes } from "../../views/Dashboard/components/shared/services/mensajes";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";

const initialDashboard = { 
    admin: {
        general: null,
        userMetrics: null,
        generalMetrics: null,
        plataformasGeneralMetrics: null,
        plataformasUserMetrics: null,
        piezasMetrics: null,
        incidenciasMetrics: null,
        proveedoresMetrics: null,
        clientesMetrics: null
    }, 
    user: { mensajes: null }
};

export const superDashboardReducer = (state = initialDashboard, action) => {
    switch(action.type){
        case "dashboard/fullfilled": {
            if(action.payload.type === 'admin') {
                const newDash = {...state, admin: { ...state.admin, general: action.payload.data } }
                return newDash;
            } else {
                const mensajes = action.payload.data.filter(msj => msj.visible).sort((a, b )=> b.id - a.id);
                const newDash = {...state, user:  { ...state.user, mensajes: mensajes } }
                return newDash;
            }
        }
        case "general-metrics/fullfilled": {
            const newDash = {...state,  admin: { ...state.admin, generalMetrics: action.payload } }
            return newDash;
        }
        case "user-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, userMetrics: action.payload } }
            return newDash;
        }
        case "plataformas-general-metrics/fullfilled": {
            const newDash = {...state,  admin: { ...state.admin, plataformasGeneralMetrics: action.payload } }
            return newDash;
        }
        case "desguaces-general-metrics/fullfilled": {
            const newDash = {...state,  admin: { ...state.admin, desguacesGeneralMetrics: action.payload } }
            return newDash;
        }
        case "desguace-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, desguacesMetrics: action.payload } }
            return newDash;
        }
        case "plataformas-user-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, plataformasUserMetrics: action.payload } }
            return newDash;
        }
        case "clientes-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, clientesMetrics: action.payload } }
            return newDash;
        }
        case "piezas-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, piezasMetrics: action.payload } }
            return newDash;
        }
        case "incidencias-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, incidenciasMetrics: action.payload } }
            return newDash;
        }
        case "proveedores-metrics/fullfilled": {
            const newDash = {...state, admin: { ...state.admin, proveedoresMetrics: action.payload } }
            return newDash;
        }
        case "recambistas-messages/fullfilled": {
            const mensajes = action.payload.filter(msj => msj.visible).sort((a, b )=> b.id - a.id);
            const newDash = {...state, user:  { ...state.user, mensajes: mensajes } }
            return newDash;
        }
        default:
            return state
    }
}

const asyncDashboard = makeAsyncTypes('dashboard');
const [setPending, setFullFilled, setError ] = asyncMac(asyncDashboard);

export const dashboardFetchingReducer = makeFetchingReducer([
    'dashboard/pending', 
    'dashboard/fullfilled', 
    'dashboard/rejected'
]);

export const fetchDashboard = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAdminData();
        const data = await response.data;
        dispatch(setFullFilled({type: 'admin', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchRefreshDashboard = () => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await refreshAdminData();
        const data = await response.data;
        dispatch(setFullFilled({type: 'admin', data: data}));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchRecambistasMessages = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getMensajes();
        const data = await response.data;
        dispatch(setFullFilled({type: 'user', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchUserMetrics = (userId, startDate, endDate, view) => async dispatch => {
    try {
        const response = await getUserMetrics(userId, startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'user-metrics/fullfilled', payload: data})
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchGeneralMetrics = (startDate, endDate, view, clean_cache) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getGeneralMetrics(startDate, endDate, view, clean_cache);
        const data = await response.data;
        dispatch({type: 'general-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchPlataformasGeneralMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        const response = await getPlataformasGeneralMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'plataformas-general-metrics/fullfilled', payload: data})
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchPlataformasUserMetrics = (plataformaId, startDate, endDate, view) => async dispatch => {
    try {
        const response = await getPlataformasUserMetrics(plataformaId, startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'plataformas-user-metrics/fullfilled', payload: data})
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchDesguacesGeneralMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getDesguacesGeneralMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'desguaces-general-metrics/fullfilled', payload: data})
    } catch (e) {
        toast.error(e.message)
    } finally {
        dispatch({type: 'loading/set', payload: false});
    }
}

export const fetchDesguacesMetrics = (desguaceId, startDate, endDate, view) => async dispatch => {
    try {
        dispatch({type: 'loading/set', payload: true});
        const response = await getDesguacesMetrics(desguaceId, startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'desguace-metrics/fullfilled', payload: data})
    }
    catch (e) {
        toast.error(e.message)
    } finally {
        dispatch({type: 'loading/set', payload: false});
    }
}

export const fetchClientesMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        const response = await getClientesMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'clientes-metrics/fullfilled', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchProveedoresMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        const response = await getProveedoresMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'proveedores-metrics/fullfilled', payload: data});
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchPiezasMetrics = (startDate, endDate, view) => async dispatch => {
    try {
        const response = await getPiezasMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch({type: 'piezas-metrics/fullfilled', payload: data})
    } catch (e) {
        toast.error(e.message)
    }
}

export const fetchIncidenciasMetrics = (resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa) => async dispatch => {
    try {
        const response = await getIncidenciasMetrics(resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa);
        const data = await response.data;
        dispatch({type: 'incidencias-metrics/fullfilled', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const dashboardReducer = superDashboardReducer;
export const dashboardStatusReducer = dashboardFetchingReducer