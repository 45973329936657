
import { toast } from 'react-toastify';
import { getReadOnlyData } from "../views/Dashboard/components/shared/services/dashboard";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer, reduceReducers } from './utils';

const initial = {};

export const readOnlyReducer = (state = initial, action) => {
    
    switch(action.type){
        case "read-only/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncReadOnly = makeAsyncTypes('read-only');

const [setPending, setFullFilled, setError ] = asyncMac(asyncReadOnly);

export const readOnlyFetchingReducer = makeFetchingReducer([
    'read-only/pending', 
    'read-only/fullfilled', 
    'read-only/rejected'
]);

const fullfilledReducer = makeSetReducer(['read-only/fullfilled']);

export const fetchReadOnlyData = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getReadOnlyData();
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'roles/fullfilled', payload: data.roles || []});
        dispatch({type: 'estados_venta/fullfilled', payload: data.estados_venta || []});
        dispatch({type: 'estados_incidencia/fullfilled', payload: data.estados_incidencia || []});
        dispatch({type: 'subestados_incidencia/fullfilled', payload: data.subestados_incidencia || []});
        dispatch({type: 'tipo_incidencias/fullfilled', payload: data.tipo_incidencias || []});
        dispatch({type: 'tipo_facturas/fullfilled', payload: data.tipo_facturas || []});
        dispatch({type: 'tipo_piezas/fullfilled', payload: data.tipo_piezas || []});
        dispatch({type: 'subtipos_incidencia/fullfilled', payload: data.subtipos_incidencia || []});
        dispatch({type: 'tipo_pagos/fullfilled', payload: data.tipo_pagos || []});
        dispatch({type: 'familia_piezas/fullfilled', payload: data.familia_piezas || []});
        dispatch({type: 'tipo_garantias/fullfilled', payload: data.tipo_garantias || []});
        dispatch({type: 'tipo_clientes/fullfilled', payload: data.tipo_clientes || []});
        dispatch({type: 'tipo_clientes_b2b/fullfilled', payload: data.tipo_clientes_b2b || []});
        dispatch({type: 'tipo_devoluciones/fullfilled', payload: data.tipo_devoluciones || []});
        dispatch({type: 'marcas/fullfilled', payload: data.marcas || []});
        dispatch({type: 'modelos/fullfilled', payload: data.modelos || []});
        dispatch({type: 'plataformas/fullfilled', payload: data.plataformas || []});
        dispatch({type: 'paises/fullfilled', payload: data.paises || []});
        dispatch({type: 'tarifas/fullfilled', payload: data.tarifas || []});
        dispatch({type: 'descuentos/fullfilled', payload: data.descuentos || []});
        dispatch({type: 'steps/fullfilled', payload: data.steps || []});
        dispatch({type: 'transportistas/fullfilled', payload: data.transportistas || []});
        dispatch({type: 'servicios_envio/fullfilled', payload: data.servicios_envio || []});
        dispatch({type: 'recambistas-messages/fullfilled', payload: data.mensajes || []});
        dispatch({type: 'recambistas/fullfilled', payload: data.recambistas || []});
        dispatch({type: 'comerciales/fullfilled', payload: data.comerciales || []});
        dispatch({type: 'atencion_cliente/fullfilled', payload: data.atencion_cliente || []});
        dispatch({type: 'administracion/fullfilled', payload: data.finanzas || []});
        dispatch({type: 'estados_piezas/fullfilled', payload: data.estados_piezas || []});
        dispatch({type: 'desguaces/fullfilled', payload: data.desguaces || []});
        dispatch({type: 'plantillas_email/fullfilled', payload: data.plantillas_email || []});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const readOnlyDataReducer = reduceReducers(readOnlyReducer, fullfilledReducer);
export const readOnlyDataStatusReducer = readOnlyFetchingReducer;