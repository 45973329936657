import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VirtualizedAutocompleteSearcher from "../../../../../shared/components/AutocompleteSearcher";

export default function MedidasForm({
    tipo,
    seguro,
    register,
    control,
    setValue,
    getValues,
    watch,
    errors,
    disabled,
    onChangeMedidas
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [disableValorAsegurado, setDisableValorAsegurado] = useState(seguro);

    useEffect(() => {
        if(tipo) {
            setValue('tipo_pieza_id', tipo.id);
            setValue('tipo_pieza', tipo);
            setValue('alto', tipo.alto);
            setValue('largo', tipo.largo);
            setValue('ancho', tipo.ancho);
            setValue('peso', parseFloat(tipo.peso));
            calcularTotales();
        }

        setDisableValorAsegurado(seguro);
    }, [tipo, seguro]);

    const handleTipoPiezasChange = (tipo) => {
        if(tipo) {
            setValue('tipo_pieza_id', tipo.id);
            setValue('tipo_pieza', tipo);
            setValue('alto', tipo.alto);
            setValue('largo', tipo.largo);
            setValue('ancho', tipo.ancho);
            setValue('peso', parseFloat(tipo.peso));
            calcularTotales();
        } else {
            setValue('tipo_pieza_id', '');
            setValue('tipo_pieza', null);
            setValue('alto', 0);
            setValue('largo', 0);
            setValue('ancho', 0);
            setValue('peso', 0);
            setValue('peso_volumetrico', 0);
            setValue('peso_total', 0);
        }

        onChangeMedidas();
    }

    const getPesoVol = () => { 
        const {alto, largo, ancho} = getValues();
        const vol = (parseFloat(alto) * parseFloat(largo) * parseFloat(ancho)) / 4000
        return parseFloat(vol).toFixed(2); 
    }

    const getPesoTotal = () => {
        const {peso, peso_volumetrico} = getValues();
        return parseFloat(peso) > parseFloat(peso_volumetrico) ? parseFloat(peso) : parseFloat(peso_volumetrico);
    }

    const calcularTotales = () => {
        const pesoVol = getPesoVol();
        setValue('peso_volumetrico', pesoVol);
        const total = getPesoTotal();
        setValue('peso_total', total);
    }

    const handleRecalcular = (e) => {
        if (e.key === "Tab") {
            e.preventDefault();
            calcularTotales();
            onChangeMedidas();
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column"> 
            <h5>Datos del paquete:</h5>

            <div className="w-100 d-flex align-items-center">
                <div className="flex-grow-1 mb-3 me-3">
                    <label className="mb-2">Tipo de pieza (opcional):</label>
                    { tipoPiezas.length > 0 &&
                        <VirtualizedAutocompleteSearcher
                            id="tipo_pieza"
                            control={control}
                            options={tipoPiezas.sort((a, b) => -b.nombre.localeCompare(a.nombre))}
                            defaultValue={tipo} 
                            labels={['nombre']}
                            disabled={disabled}
                            onChange={handleTipoPiezasChange}
                        />
                    }
                </div>

                <div className="d-flex flex-column">
                    <label>Total Volumétrico: {watch('peso_volumetrico')} KG</label>
                    <label>Peso Total: {watch('peso_total')} KG</label>
                </div>
                
            </div>

            <input 
                type="hidden" 
                {...register("tipo_pieza_id")} 
                defaultValue={''} />
            <input 
                type="hidden" 
                {...register("peso_total")} 
                defaultValue={0} />
             <input 
                type="hidden" 
                {...register("peso_volumetrico")} 
                defaultValue={0} />

            <div className="w-100 d-flex">
                <div className="form-group flex-grow-1 me-3">
                    <label htmlFor="peso">Peso</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            disabled={disabled}
                            {...register("peso", { required: true })} 
                            defaultValue={0} />
                        <span className="text-success icon">
                            <b>KG</b>
                        </span>
                    </div>
                    
                    <div className="form-invalid">
                        {errors.peso?.type === 'required' && "Peso es obligatorio"}
                    </div>
                </div>

                <div className="form-group flex-grow-1 me-3">
                    <label htmlFor="largo">Largo</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            disabled={disabled}
                            {...register("largo", { required: true })} 
                            defaultValue={0} />
                        <span className="text-success icon">
                            <b>CM</b>
                        </span>
                    </div>
                    
                    <div className="form-invalid">
                        {errors.largo?.type === 'required' && "Largo es obligatorio"}
                    </div>
                </div>

                <div className="form-group flex-grow-1 me-3">
                    <label htmlFor="ancho">Ancho</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            disabled={disabled}
                            {...register("ancho", { required: true })} 
                            defaultValue={0} />
                        <span className="text-success icon">
                            <b>CM</b>
                        </span>
                    </div>
                    
                    <div className="form-invalid">
                        {errors.ancho?.type === 'required' && "Largo es obligatorio"}
                    </div>
                </div>

                <div className="form-group flex-grow-1 me-3">
                    <label htmlFor="alto">Alto</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="1"
                            className="form-control" 
                            min={0}
                            disabled={disabled}
                            {...register("alto", { required: true })} 
                            defaultValue={0} 
                            onKeyDown={handleRecalcular}
                        />
                        <span className="text-success icon">
                            <b>CM</b>
                        </span>
                    </div>
                    
                    <div className="form-invalid">
                        {errors.alto?.type === 'required' && "Alto es obligatorio"}
                    </div>
                </div>

                <div className="form-group flex-grow-1">
                    <label htmlFor="valor_asegurado">Valor Asegurado (seguro)</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            disabled={disableValorAsegurado}
                            {...register("valor_asegurado", { required: true })} 
                            defaultValue={0} 
                            onKeyDown={handleRecalcular}
                        />
                        <span className="text-success icon">
                            <b>€</b>
                        </span>
                    </div>
                    
                    <div className="form-invalid">
                        {errors.valor_asegurado?.type === 'required' && "Valor es obligatorio"}
                    </div>
                </div>
            </div>
        </div>
    )
}