import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import EnvioSearcher from "../../../../../../../../../../../shared/components/EnvioSearcher";
import { useSelector } from "react-redux";

export default function EnvioForm({
    cliente,
    pieza,
    medidas,
    direccionEnvio,
    onSubmit
}) {
    /** Form **/
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            proveedor_id: pieza.proveedor_id,
            peso_total: 0,
            pieza_id: pieza.id,
            precio_transporte: 0,
            precio_tarifa: 0,
            transportista_id: 0,
            servicio_envio_id: 0,
            seguro: 0,
            porcentaje_seguro: 0,
            valor_asegurado: 0,
            observaciones: '',
            recogida_id: ''
        }
    });

    /** States **/
    const [envioData, setEnvioData] = useState(null);
    const [servicioSeleccionado, setServicioSeleccionado] = useState(false);

    /** Redux **/
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    /** Lifecycle **/
    useEffect(() => {
        if(pieza && medidas) {
            const data = {
                medidas: {
                    ...medidas,
                    peso_volumetrico: parseFloat(((medidas.alto * medidas.largo * medidas.ancho) / 4000)),
                    tipo_pieza_id: pieza.tipo_pieza_id
                },
                pieza
            };
            setEnvioData(data);
        }
    }, [pieza, medidas]);

    /** Methods **/
    const getPesoTotalPieza = () => {
        const totalVolumetrico = (((medidas?.alto * medidas?.largo * medidas?.ancho) / 4000));
        const totalPeso = parseFloat(medidas?.peso);
        if (totalPeso > totalVolumetrico) return totalPeso;
        else return totalVolumetrico;
    }

    const handleSubmitForm = (data, e) => {
        e.preventDefault();
        onSubmit(data);
    }

    const handleServicioEnvio = (servicio, proveedor) => {
        setServicioSeleccionado(true);
        const tipoPieza = tipoPiezas.find(tp => tp.id === parseInt(pieza.tipo_pieza_id));
        const peso = servicio.transportista_id === 2 ? getPesoTotalPieza() : tipoPieza.peso;

        methods.setValue(`proveedor_id`, proveedor.id);
        methods.setValue(`peso_total`, peso);

        methods.setValue(`seguro`, servicio.seguro);
        methods.setValue(`porcentaje_seguro`, servicio.seguro ? servicio.porcentaje_seguro : 0);
        methods.setValue(`valor_asegurado`, servicio.seguro ? servicio.valor_asegurado : 0);

        methods.setValue(`precio_transporte`, servicio.precio_transporte);
        methods.setValue(`precio_tarifa`, servicio.precio_tarifa);
        methods.setValue(`transportista_id`, servicio.transportista_id);
        methods.setValue(`servicio_envio_id`, servicio.servicio_envio_id);
        methods.setValue(`recogida_id`, servicio.recogida_id);
    }

    /** Render **/
    return (
        <FormProvider {...methods}>
            <form className="form my-2" onSubmit={methods.handleSubmit(handleSubmitForm)}>
                <div className="w-100">
                    <input type="hidden"
                        name={`proveedor_id`}
                        {...methods.register(`proveedor_id`)}
                        defaultValue={pieza.proveedor_id}
                    />
                    <input type="hidden"
                        name={`peso_total`}
                        {...methods.register(`peso_total`)}
                        defaultValue={getPesoTotalPieza(pieza)}
                    />
                    <input type="hidden"
                        name={`piezas`}
                        {...methods.register(`piezas`)}
                        defaultValue={pieza.id}
                    />
                    <input type="hidden"
                        name={`precio_transporte`}
                        {...methods.register(`precio_transporte`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`precio_tarifa`}
                        {...methods.register(`precio_tarifa`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`transportista_id`}
                        {...methods.register(`transportista_id`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`servicio_envio_id`}
                        {...methods.register(`servicio_envio_id`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`seguro`}
                        {...methods.register(`seguro`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`porcentaje_seguro`}
                        {...methods.register(`porcentaje_seguro`)}
                        defaultValue={0}
                    />
                    <input type="hidden"
                        name={`valor_asegurado`}
                        {...methods.register(`valor_asegurado`)}
                        defaultValue={0}
                    />

                    {(envioData) &&
                        <EnvioSearcher
                            fromPresupuesto={true}
                            cliente={cliente}
                            setValue={methods.setValue}
                            control={methods.control}
                            getValues={methods.getValues}
                            register={methods.register}
                            tipoPiezaId={parseInt(pieza.tipo_pieza_id)}
                            pieza={envioData.pieza}
                            proveedor={envioData.pieza.proveedor}
                            medidas={envioData.medidas}
                            direccionEnvio={direccionEnvio}
                            onSelectServicio={handleServicioEnvio}
                            tipoClienteDestino={cliente.tipo_cliente_id}
                        />
                    }

                    <div className="form-group flex-grow-1 mt-3">
                        <label>Observaciones</label>
                        <textarea
                            {...methods.register(`observaciones`)}
                            defaultValue={''} >
                        </textarea>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-end">
                        <button
                            type="submit"
                            disabled={!servicioSeleccionado || (!methods.watch('servicio_envio_id') && !methods.watch('proveedor_id') && !methods.watch('transportista_id') && !methods.watch('precio_transporte'))}
                            className="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </form>
        </FormProvider>
    )
}