import React from "react";

export default function PresupuestoTotalTable({
    total, 
    envio, 
    casco,
    subtotal,
    iva, 
    total_iva,
    total_descuento,
    total_descuento_iva,
    valeDescuento
}) {

    const getSubtotal = () => {
        return parseFloat(subtotal).toFixed(2)
    }

    const getTotalIVA = () => {
        const totalIVA = parseFloat(total_iva) + parseFloat(total_descuento_iva);
        return parseFloat(totalIVA).toFixed(2)
    }

    return (
        <div className="col-12 d-flex flex-column mt-3">
            <table className="w-100 total-table">
                <tbody>
                    <tr>
                        <td>
                            <small>Total</small>
                        </td>
                        <td className="text-end">
                            <p>{parseFloat(total).toFixed(2)}€</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>Casco</small>
                        </td>
                        <td className="text-end">
                            <p>{parseFloat(casco).toFixed(2)}€</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>Envio</small>
                        </td>
                        <td className="text-end">
                            <p>{parseFloat(envio).toFixed(2)}€</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <hr className="my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>
                                <span className="fw-bold">Subtotal</span>
                            </small>
                        </td>
                        <td className="text-end">
                            <p>{getSubtotal()}€</p>
                        </td>
                    </tr>
                    {valeDescuento && total_descuento > 0 && <tr>
                                <td>
                                    <small>
                                        Código 
                                        <span className="fw-bold text-green ms-2">{valeDescuento.codigo}</span>
                                    </small>
                                </td>
                                <td className="text-end">
                                    <p>-{parseFloat(total_descuento_iva).toFixed(2)}€</p>
                                </td>
                            </tr>
                    }
                    <tr>
                        <td>
                            <small>IVA</small>
                        </td>
                        <td className="text-end">
                            <p>{iva}%</p>
                        </td>
                    </tr>
                    
                    { (valeDescuento && total_descuento_iva > 0) &&
                        <>
                            <tr>
                                <td>
                                    <small>Total IVA</small>
                                </td>
                                <td className="text-end">
                                    <p>{getTotalIVA()}€</p>
                                </td>
                            </tr>
                            {valeDescuento && <tr>
                                <td>
                                    <small>
                                        Código 
                                        <span className="fw-bold text-green ms-2">{valeDescuento.codigo}</span>
                                    </small>
                                </td>
                                <td className="text-end">
                                    <p>-{parseFloat(total_descuento_iva).toFixed(2)}€</p>
                                </td>
                            </tr>}
                        </>
                    }
                    <tr>
                        <td colSpan={2}>
                            <hr className="my-2" />
                        </td>
                    </tr>

                    <tr className="total">
                        <td>
                            <small>Total IVA</small>
                        </td>
                        <td className="text-end">
                            <p>{parseFloat(total_iva).toFixed(2)}€</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}