import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import GooglePlacesAutocomplete from "../../../GooglePlacesAutocomplete";

export default function DireccionClienteForm({
    cliente,
    direccion,
    isEdit,
    isNew,
    onSubmitDireccion,
    onCancel,
}) {
    const hourRange = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const direccionSchema = yup.object({
        nombre: yup.string().nullable().required("El nombre es obligatorio"),
        direccion:  yup.string().nullable().required("La dirección es obligatoria"),
        codigo_postal:  yup.string().nullable().required("El código postal es obligatorio"),
        poblacion: yup.string().nullable().required("La población es obligatoria"),
        area: yup.string().nullable().required("La área es obligatoria"),
        pais_id: yup.number().integer().nullable().required("El país es obligatorio")
    }).required();

    const { 
        register,
        formState: { errors }, 
        setValue,
        reset,
        getValues,
        handleSubmit, 
        watch,
        setError,
     } = useForm({
            resolver: yupResolver(direccionSchema),
            defaultValues: {
                id: '',
                nombre: '',
                telefono: '',
                direccion: '',
                horario_inicio_dia: "",
                horario_fin_dia: "",
                horario_inicio_tarde: "",
                horario_fin_tarde: "",
                codigo_postal: '',
                poblacion: '',
                area: '',
                pais_id: 1,
            }
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });

    const [horasInicioDia, setHorasInicioDia] = useState(hourRange);
    const [horasInicioTarde, setHorasInicioTarde] = useState(hourRange);
    const [horasFinDia, setHorasFinDia] = useState(hourRange);
    const [horasFinTarde, setHorasFinTarde] = useState(hourRange);

    useEffect(() => {      
        if(isNew) resetValues();
        if(isEdit) reset(direccion);
    }, [direccion, isNew, isEdit]);

    const resetValues = () => {
        reset({
            id: '',
            nombre: '',
            telefono: '',
            direccion: '',
            horario_inicio_dia: "",
            horario_fin_dia: "",
            horario_inicio_tarde: "",
            horario_fin_tarde: "",
            codigo_postal: '',
            poblacion: '',
            area: '',
            pais_id: 1,
            
        })
    }

    const handleChangePais = async(e) => {
        const value = parseInt(e.target.value);

        setValue('poblacion', '');
        setValue('codigo_postal', '');
        setValue('direccion', '')
        setValue('area', '');
        setValue('pais_id', value);
    }

    const handleChangeDireccion = (direccion) => {
        Object.keys(direccion).forEach(function(key) {
            setValue(key, direccion[key])
        });
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(!checkIfNameIsNotEmail(data.nombre)) return
        normalizeScheduleKeys(data)
        data.entity_id = cliente.id;

        onSubmitDireccion(data);
    }

    const normalizeScheduleKeys = (data) => {
        //2 digits numbers
        const pad = (num) => num?.toString()?.padStart(2, '0');
        
        if(data.horario_inicio_dia && data.horario_fin_dia) {
          data.horario_entrega_dia = `${pad(data.horario_inicio_dia)}:00-${pad(data.horario_fin_dia)}:00`;
        } else if (data.horario_entrega_dia && data.horario_inicio_dia) {
          data.horario_entrega_dia = `${pad(data.horario_inicio_dia)}:00-${data.horario_entrega_dia.split('-')[1]}`;
        } else if (data.horario_entrega_dia && data.horario_fin_dia) {
          data.horario_entrega_dia = `${data.horario_entrega_dia.split('-')[0]}-${pad(data.horario_fin_dia)}:00`;
        } else if (data.horario_inicio_dia === '' && data.horario_fin_dia === '') {
          data.horario_entrega_dia = null
        }
        
        if(data.horario_inicio_tarde && data.horario_fin_tarde) {
          data.horario_entrega_tarde = `${pad(data.horario_inicio_tarde)}:00-${pad(data.horario_fin_tarde)}:00`;
        } else if (data.horario_entrega_tarde && data.horario_inicio_tarde) {
          data.horario_entrega_tarde = `${pad(data.horario_inicio_tarde)}:00-${data.horario_entrega_tarde.split('-')[1]}`
        } else if (data.horario_entrega_tarde && data.horario_fin_tarde) {
          data.horario_entrega_tarde = `${data.horario_entrega_tarde.split('-')[0]}-${pad(data.horario_fin_tarde)}:00`;
        } else if (data.horario_inicio_tarde === '' && data.horario_fin_tarde === '') {
          data.horario_entrega_tarde = null
        }
    
        delete data.horario_inicio_dia;
        delete data.horario_fin_dia; 
        delete data.horario_inicio_tarde;
        delete data.horario_fin_tarde;
    }

    const cancel = () => { onCancel(); }

    const checkIfNameIsNotEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = regex.test(email?.trim().toLowerCase())
        if(isEmail) {
            setError('nombre', {type: 'valid', message: 'El nombre no puede ser un email.'})
            return false;
        }
        return true;
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                <form className="form mb-4 flex-grow-1" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100">
                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="nombre">Nombre</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("nombre")} 
                                    defaultValue={isEdit ? direccion.nombre : ''} />

                                <div className="form-invalid">
                                    {(errors.nombre) && errors.nombre?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="telefono">Teléfono</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("telefono")} 
                                    defaultValue={isEdit ? direccion.telefono : ''} />
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center mb-2">
                            <div className="d-flex flex-column mb-3 me-3">
                                <label className="mb-1" htmlFor="pais_id">País</label>
                                <select 
                                    {...register("pais_id", {required: true})}
                                    defaultValue={isEdit ? direccion.pais_id : 1}
                                    onChange={handleChangePais}>
                                    {paises?.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>

                                <div className="form-invalid">
                                    {(errors.pais_id) && errors.pais_id?.message}
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label className="mb-1">Buscar una dirección</label>

                                <GooglePlacesAutocomplete   
                                    type={'address'}
                                    types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                    country={watch('pais_id') ? paises?.find(p => p.id === parseInt(getValues('pais_id')))?.codigo : ''}
                                    onSelectResult={handleChangeDireccion}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column mb-3 me-3">
                                <label htmlFor="codigo_postal" style={{marginBottom: '5px'}}>Código postal</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("codigo_postal", { maxLength: 150 })} 
                                    defaultValue={isEdit ? direccion.codigo_postal : ''} />

                                <div className="form-invalid">
                                    {(errors.codigo_postal) && errors.codigo_postal?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("direccion")} 
                                    defaultValue={isEdit ? direccion.direccion : ''} />
                                
                                <div className="form-invalid">
                                    {(errors.direccion) && errors.direccion?.message}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group me-3">
                                <label htmlFor="direccion_facturacion">Población</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("poblacion", { maxLength: 150 })} 
                                    defaultValue={isEdit ? direccion.poblacion : ''} />

                                <div className="form-invalid">
                                    {(errors.poblacion) && errors.poblacion?.message}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="direccion_facturacion">Area (Provincia, CCAA)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Provincia, CCAA"
                                    {...register("area", { maxLength: 150 })} 
                                    defaultValue={isEdit ? direccion.area : ''} />

                                <div className="form-invalid">
                                    {(errors.area) && errors.area?.message}
                                </div>
                            </div>
                        </div>

                        <h6 className="text-uppercase mt-3">Horarios de entrega</h6>

                        <div className="w-100 d-flex align-items-center">
                            <div className="flex-grow-1 me-3">   
                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="nombre">Inicio horario de entrega</label>

                                        <select 
                                            defaultValue={isEdit && direccion?.horario_entrega ? parseInt(direccion?.horario_entrega.split('-')[0]) : ""}
                                            onChange={(e) => {
                                                if(e.target.value !== "") {
                                                    const value = parseInt(e.target.value);
                                                    const index = hourRange.findIndex(f => f === value);
                                                    const newEndRange = hourRange.slice(index+1, hourRange.length);

                                                    setHorasFinDia(newEndRange);
                                                    setValue('horario_inicio_dia', value);
                                                }
                                            }}
                                        >
                                            <option key={'opt--1'} value={""}>Selecciona una hora...</option>
                                            {horasInicioDia?.map(op => <option key={'schedule-sm-' + op} value={op}>{op + ':00h'}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="telefono">Fin horario de entrega</label>
                                        <select 
                                            defaultValue={isEdit && direccion?.horario_entrega ? parseInt(direccion?.horario_entrega.split('-')[1]) : ""}
                                            onChange={(e) => {
                                                if(e.target.value !== "") {
                                                    const value = parseInt(e.target.value);
                                                    const index = hourRange.findIndex(f => f === value);
                                                    const newStartRange = hourRange.slice(0, index);

                                                    setHorasInicioDia(newStartRange);
                                                    setValue('horario_fin_dia', value);
                                                }
                                            }}
                                        >
                                            <option key={'opt--1'} value={""}>Selecciona una hora...</option>
                                            {horasFinDia?.map(op => <option key={'schedule-em-' + op} value={op}>{op + ':00h'}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-grow-1">
                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="nombre">Inicio horario de entrega</label>

                                        <select 
                                            defaultValue={isEdit && direccion?.horario_entrega ? parseInt(direccion?.horario_entrega.split('-')[0]) : ""}
                                            onChange={(e) => {
                                                if(e.target.value !== "") {
                                                    const value = parseInt(e.target.value);
                                                    const index = hourRange.findIndex(f => f === value);
                                                    const newEndRange = hourRange.slice(index+1, hourRange.length);

                                                    setHorasFinTarde(newEndRange);
                                                    setValue('horario_inicio_tarde', value);
                                                }
                                            }}
                                        >
                                            <option key={'opt--1'} value={""}>Selecciona una hora...</option>
                                            {horasInicioTarde?.map(op => <option value={op} key={'schedule-sa-' + op}>{op + ':00h'}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="telefono">Fin horario de entrega</label>
                                        <select 
                                            defaultValue={isEdit && direccion?.horario_entrega ? parseInt(direccion?.horario_entrega.split('-')[1]) : ""}
                                            onChange={(e) => {
                                                if(e.target.value !== "") {
                                                    const value = parseInt(e.target.value);
                                                    const index = hourRange.findIndex(f => f === value);
                                                    const newStartRange = hourRange.slice(0, index);

                                                    setHorasInicioTarde(newStartRange);
                                                    setValue('horario_fin_tarde', value);
                                                }
                                            }}
                                        >
                                            <option key={'opt--1'} value={""}>Selecciona una hora...</option>
                                            {horasFinTarde?.map(op => <option value={op} key={'schedule-ea-' + op}>{op + ':00h'}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="w-100 d-flex align-items-center justify-content-end">
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            <button type="button" className="btn btn-default ms-3" onClick={() => cancel()}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}