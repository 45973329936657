import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";
import MultiFileUploadForm from "../../../shared/components/MultiFileUploadForm";
import LightBoxImages from "../../../shared/components/LightBoxImages";
import { getImgsDevolucionMercancias } from '../../../shared/services/devoluciones_mercancias';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

export default function Images({
    devolucion,
    onUpload,
    onDelete
}) {
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [imagenes, setImagenes] = useState(null);
 
    useEffect(() => {
        if(!imagenes) {
            getImages(devolucion.id);
        } else {
            const imgSRCs = imagenes.map((img) => {
             return `data:image/jpeg;base64,${img}`;
            })
            setImagesSRCs(imgSRCs);
        }
    }, [devolucion, imagenes]);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    const getImages = async (devolucionId) => {
        const res = await getImgsDevolucionMercancias(devolucionId);
        if(res.success) {
            return setImagenes(res.data);
        }
        toast.error('Error al obtener las imágenes.')
        setImagenes([]);
    }

    return (
        <div className="w-100 d-flex flex-column">
           { (3 - imagenes?.length > 0) &&
            <div className="w-100 mt-3">
                <MultiFileUploadForm 
                    itemId={"files"}
                    labelText={<>Imágenes, <b>máximo {3 - imagenes?.length}</b></>}
                    onSubmit={onUpload}
                    maxFilesCount={3 - imagenes?.length}
                />
            </div>
            }

            <div className="w-100 d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                { !imagenes
                    ? <CircularProgress size="20px" color='inherit'/>
                    : imagenes?.length > 0
                        ? imagenes?.map(({img, nombre}, i) => {
                            return (
                                <div className="d-flex flex-column align-items-center mb-4 me-4" key={'images-' + i}>
                                    <img 
                                        key={"Imagen " + (i+1)} 
                                        src={`data:image/jpeg;base64,${img}`}
                                        alt={"Imagen " + (i+1)} 
                                        width={'200px'}
                                        height={'200px'}
                                        style={{cursor: 'pointer', objectFit: 'cover'}}
                                        onClick={() => handleOpenLightBox(i)}
                                    />

                                    <span title="Eliminar" style={{cursor: 'pointer'}} onClick={() => onDelete(nombre)} >
                                        <FontAwesomeIcon icon={faTrash} size="1x" className="action mt-2" color="#dc3545"/>
                                    </span>
                                </div>
                            )})
                        : <p>No hay imágenes.</p>}
            </div>

            { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    )
}